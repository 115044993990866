<template>
  <div class="submit-order">
    <HeadNav />

    <div v-if="addressStatus" class="order-address" @click="selectAddress">
      <div v-if="address_info">
        <p class="user-info">
          <span class="user-name">{{ address_info.realname }}</span>
          <span class="user-phone">{{ address_info.mobile }}</span>
        </p>
        <p class="user-address">
          {{ address_info.area_string }}{{ address_info.address }}
        </p>
      </div>
      <div v-else class="no-address">请填写收货信息</div>
      <div class="order-line" />
    </div>

    <!--订单内容-->
    <div class="order-content">
      <order-goods
        v-if="goods_list.length > 0"
        :goodsList="goods_list"
        :show_max="true"
        :showType="true"
      ></order-goods>

      <div class="order-info">
        <div class="info-title">订单基本信息</div>
        <div class="info-item">
          <p>商品总价</p>
          <template v-if="businessType == 2">
            <p class="fs-weight">
              {{ total_cash > 0 ? `${total_cash}元` : `` }}
              {{ total_cash > 0 && total_integral > 0 ? `+` : `` }}
              {{ total_integral > 0 ? `${total_integral}积分` : `` }}
            </p>
          </template>
          <template v-else>
            <p class="fs-weight">¥{{ total_price }}</p>
          </template>
        </div>

        <div v-if="businessInfo.type !== 3 && is_kill === 1" class="info-item">
          <p>满减</p>
          <template v-if="businessType == 2">
            <p
              v-if="
                !full_reduction && JSON.stringify(max_marketing_info) === '{}'
              "
              @click="reduction_action = true"
              class="prompt pr36 arrow"
            >
              暂无可用满减
            </p>
            <p
              v-else-if="reductionVal"
              @click="reduction_action = true"
              class="prompt pr36 arrow"
            >
              暂无可用满减
            </p>
            <p
              v-else-if="JSON.stringify(max_marketing_info) !== '{}'"
              @click="reduction_action = true"
              class="pr36 arrow fs-weight color-main"
            >
              -{{ full_reduction }}积分
            </p>
          </template>
          <template v-else>
            <p
              v-if="!full_reduction"
              @click="reduction_action = true"
              class="prompt pr36 arrow"
            >
              暂无可用满减
            </p>
            <p
              v-else
              @click="reduction_action = true"
              class="pr36 arrow fs-weight color-main"
            >
              -¥{{ full_reduction }}
            </p>
          </template>
        </div>

        <div
          v-if="
            businessInfo.type !== 3 &&
              add_type !== 3 &&
              is_group == 1 &&
              is_kill === 1
          "
          class="info-item"
        >
          <p>优惠券</p>
          <template v-if="businessType == 2">
            <p
              v-if="coupon"
              @click="show_coupons = true"
              class="fs-weight color-main"
            >
              -{{ coupon }}积分
            </p>
            <p v-else @click="show_coupons = true" class="prompt pr36 arrow">
              {{ coupons_list.length > 0 ? "请选择优惠券" : "暂无可用优惠券" }}
            </p>
          </template>
          <template v-else>
            <p
              v-if="coupon"
              @click="show_coupons = true"
              class="fs-weight color-main"
            >
              -¥{{ coupon }}
            </p>
            <p v-else @click="show_coupons = true" class="prompt pr36 arrow">
              {{ coupons_list.length > 0 ? "请选择优惠券" : "暂无可用优惠券" }}
            </p>
          </template>
        </div>

        <div v-if="businessInfo.type !== 3" class="info-item">
          <p>商品实付</p>
          <template v-if="businessType == 2">
            <p class="fs-weight">
              {{ total_cash > 0 ? `${total_cash}元` : `` }}
              {{ total_cash > 0 && actually_integral > 0 ? `+` : `` }}
              {{ actually_integral > 0 ? `${actually_integral}积分` : `` }}
            </p>
          </template>
          <template v-else>
            <p class="fs-weight">
              ¥{{ actually_price >= 0 ? actually_price : 0 }}
            </p>
          </template>
        </div>

        <div class="info-item">
          <p>运费</p>
          <template v-if="businessType == 2">
            <p v-if="explode_fee_text.length < 1" class="fs-weight">
              {{
                express_fee_cash == 0 && express_fee == 0
                  ? 0 + "积分"
                  : express_fee_cash > 0
                  ? express_fee_cash + "元"
                  : "" + integralPart(1, express_fee, 100, 1) > 0
                  ? integralPart(1, express_fee, 100, 1) + "积分"
                  : ""
              }}
            </p>
            <p v-else class="fs-weight">
              {{
                express_fee_cash > 0
                  ? express_fee_cash +
                    "元" +
                    (express_fee > 0 ? "+" + express_fee + "积分" : "")
                  : express_fee > 0
                  ? express_fee + "积分"
                  : ""
              }}
            </p>
          </template>
          <template v-else>
            <p class="fs-weight">¥{{ express_fee }}</p>
          </template>
        </div>

        <div
          class="explode-text"
          v-for="(item, index) in explode_fee_text"
          :key="index"
        >
          <div class="explode-layout">
            <p class="text-layout">{{ item }}</p>
            <div
              v-if="businessType == 2 && Number(moneyPay) && pay_type != 15"
              class="pay-type"
            >
              <yd-checkbox-group
                v-model="old_explode_fee_key[index]"
                size="14"
                :callback="selectType"
              >
                <yd-checkbox val="old_explode_fee_key[index]" shape="circle">
                  <span style="font-size: 0.28rem;">现金</span>
                </yd-checkbox>
              </yd-checkbox-group>
            </div>
          </div>
        </div>

        <div v-if="businessInfo.type !== 3" class="info-all">
          <template v-if="businessType == 2">
            <p class="fs-weight color-main">
              {{
                total_cash > 0 || express_fee_cash > 0
                  ? `${(Number(total_cash) + express_fee_cash).toFixed(2)}元`
                  : ``
              }}
              {{
                (total_cash > 0 && together_price > 0) ||
                (express_fee_cash && together_price > 0)
                  ? `+`
                  : ``
              }}
              {{ together_price > 0 ? `${together_price}积分` : `` }}
            </p>
          </template>
          <template v-else>
            <p class="fs-weight color-main">
              ¥{{
                actually_price >= 0 ? together_price : express_fee.toFixed(2)
              }}
            </p>
          </template>
          <p>合计</p>
        </div>
      </div>

      <div v-if="businessInfo.type !== 3" class="pick-list">
        <!--  recharge_type   1卡密，2直充-->
        <div v-if="recharge_type == 2" class="order-line">
          <p>充值账号</p>
          <input
            type="text"
            class="account_input"
            v-model="recharge_account"
            placeholder="如手机号、QQ号、油卡号等"
            style="text-align: center;"
          />
        </div>
        <!--  recharge_type   1卡密，2直充-->

        <div
          v-if="
            add_type !== 3 &&
              pay_type !== 7 &&
              pay_type !== 10 &&
              pay_type !== 13 &&
              pay_type !== 15 &&
              fk_show &&
              tripartite_type !== 'VUE_APP_ZJG'
          "
        >
          <div
            class="order-line b0"
            @click="show_payCheck()"
            v-if="pay_type != 21"
          >
            <p>支付方式</p>
            <p
              v-if="pay_type === 2 && businessInfo.type === 1"
              class="arrow-grey"
            >
              余额支付
            </p>
            <p
              v-else-if="
                pay_type === 1 &&
                  businessInfo.type !== 3 &&
                  moneyPay == 1 &&
                  wxStatus
              "
              class="arrow-grey"
            >
              微信支付
            </p>
            <p
              v-else-if="pay_type === 3 && businessInfo.type === 2"
              class="arrow-grey"
            >
              积分支付
            </p>
            <p
              v-else-if="
                pay_type === 5 &&
                  businessInfo.type === 1 &&
                  bid === '546e0d7595018800a6a44895274b92b1'
              "
              class="arrow-grey"
            >
              预付卡支付
            </p>
            <p v-else-if="pay_type === 11" class="arrow-grey">支付宝支付</p>
            <p v-else-if="pay_type === 12" class="arrow-grey">微信支付</p>
            <p v-else class="prompt arrow-grey">选择支付方式</p>
          </div>
        </div>

        <div>
          <div v-if="mix_price > 0" class="mix-box df fdr jc-sb">
            <span v-if="pay_type === 2">
              钱包余额¥{{ balance }}，全部支付后还需
              <br />
              支付¥{{ mix_price }}
            </span>

            <!-- 积分商城开启补价，并不含有积分+现金类型商品 -->
            <span
              v-if="
                (pay_type === 3 && cashToInt == 1 && !cash_intrgral) ||
                  (pay_type === 10 && cashToInt == 1 && !cash_intrgral)
              "
            >
              积分余额{{ integral }}
              {{ mix_price > 0 ? `,全部支付后还需${mix_price}元` : `` }}
              <yd-checkbox-group v-model="morePay" style="margin-top: 0.2rem;">
                <yd-checkbox val="1" shape="circle">同意补价</yd-checkbox>
              </yd-checkbox-group>
            </span>
            <!-- 积分商城开启补价，并不含有积分+现金类型商品 -->

            <!-- 积分商城开启补价，但商品中含有积分+现金类型商品 -->
            <span
              v-if="
                (pay_type === 3 && cashToInt == 1 && cash_intrgral) ||
                  (pay_type === 10 && cashToInt == 1 && cash_intrgral)
              "
            >
              您的账号积分余额{{ integral }},不够支付该订单，无法下单！
            </span>
            <!-- 积分商城开启补价，但商品中含有积分+现金类型商品 -->

            <!-- 积分商城未开启补价 -->
            <span
              v-if="
                (pay_type === 3 && cashToInt == 0) ||
                  (pay_type === 10 && cashToInt == 0)
              "
            >
              您的账号积分余额{{ integral }},不够支付该订单，无法下单！
            </span>
            <!-- 积分商城未开启补价 -->
          </div>

          <!-- 积分余额够，但没开启现金支付 -->
          <div class="mix-box df fdr jc-sb" v-else>
            <span v-if="moneyPay == 0 && total_cash > 0">
              商城没有开启现金支付，无法下单！
            </span>
          </div>
          <!-- 积分余额够，但没开启现金支付 -->
        </div>

        <!-- 饭卡支付 -->
        <div v-if="!fk_show">
          <div class="order-line b0" @click="show_pay = true">
            <p>支付方式</p>
            <p v-if="pay_type == 20" class="arrow-grey">饭卡支付</p>
            <p v-else-if="pay_type === 1" class="arrow-grey">微信支付</p>
            <p v-else class="prompt arrow-grey">选择支付方式</p>
          </div>
        </div>
        <!-- 饭卡支付 -->
      </div>
      <div v-else class="order-info">
        <div class="order-line" v-if="budget_list">
          <p>预算类型</p>
          <p class="budget-arrow-grey">
            <select v-model="budget_type_id">
              <option value="" style="color: #999999;">请选择预算类型</option>
              <option
                style="color: #333;"
                :value="item.id"
                v-for="(item, index) in budget_list"
                :key="index"
                >{{ item.name }}</option
              >
            </select>
          </p>
        </div>
        <div class="order-line" @click="toOrderNote">
          <p>订单备注</p>
          <p v-if="!user_remark" class="prompt arrow-grey">订单备注哦</p>
          <p v-else class="arrow-grey">{{ user_remark }}</p>
        </div>
      </div>

      <div v-html="icbcHtml"></div>
    </div>

    <!--选择优惠券-->
    <div class="coupons-list" v-if="show_coupons">
      <yd-popup v-model="show_coupons" position="bottom" height="79.2%">
        <div class="title-fix">
          <div class="title border-bottom-1px">
            优惠券
            <img
              @click="show_coupons = false"
              src="../../assets/ic_close.png"
              alt
            />
          </div>
          <div class="tab-box df fdr alc jc-sb">
            <div
              v-for="(item, index) in tab_list"
              :key="index"
              :class="
                index + 1 === current_tab ? 'select-item tab-item' : 'tab-item'
              "
              @click="selectTab(index)"
            >
              {{ item }}
              <div v-if="index + 1 === current_tab" class="line" />
            </div>
          </div>
        </div>

        <!--优惠券列表-->
        <div v-if="coupons_list.length > 0" class="coupons-box">
          <CouponList
            ref="coupons"
            slot="coupons_list"
            :is_start="true"
            :list="coupons_list"
            @operate="operate"
          ></CouponList>
          <div style="margin-bottom: 1.38rem;"></div>
        </div>
        <!--优惠券列表-->

        <!--优惠券为空-->
        <div v-if="coupons_list.length === 0" class="empty-box">
          <Empty tip="暂无优惠券～" :icon="emp_img"></Empty>
        </div>
        <!--优惠券为空-->

        <div v-if="coupons_list.length > 0" class="no-coupons">
          <div class="no-btn" @click.stop="noUseCoupons">不使用优惠券</div>
        </div>
      </yd-popup>
    </div>
    <!--选择优惠券-->

    <!-- 选择满减 -->
    <div v-if="reduction_action" class="coupons-list">
      <yd-popup v-model="reduction_action" position="bottom" height="79.2%">
        <div class="title-fix">
          <div class="title border-bottom-1px">
            满减活动
            <img
              @click="reduction_action = false"
              src="../../assets/ic_close.png"
              alt
            />
          </div>
        </div>

        <!--满减列表-->
        <div
          v-if="reduction_list.length > 0"
          class="coupons-box couponsBox empty-couponsBox"
        >
          <CouponList
            ref="reduction"
            slot="reduction_list"
            :is_start="true"
            :list="reduction_list"
            @operate="useReduction"
          ></CouponList>
          <div style="margin-bottom: 1.38rem;"></div>
        </div>
        <!--满减列表-->

        <!--满减为空-->
        <div
          v-if="reduction_list.length === 0"
          class="empty-box couponsBox empty-height"
        >
          <Empty tip="暂无满减～" :icon="emp_img"></Empty>
        </div>
        <!--满减为空-->

        <div v-if="reduction_list.length > 0" class="no-coupons couponsBox">
          <div class="no-btn" @click.stop="noUseReduction">不使用满减</div>
        </div>
      </yd-popup>
    </div>
    <!-- 选择满减 -->

    <!--卡券-->
    <div v-if="show_card_pay">
      <card_pay @cardPayOrder="cardPayOrder"></card_pay>
    </div>
    <!--卡券-->

    <!--选择支付方式-->
    <div class="payment-box">
      <yd-popup
        v-if="jpkStatus && fk_show"
        v-model="show_pay"
        position="bottom"
      >
        <div v-if="!wxStatus" class="pay-item" @click="changePayType(11)">
          支付宝支付
        </div>
        <div v-if="wxStatus" class="pay-item" @click="changePayType(1)">
          微信支付
        </div>
        <div v-else class="pay-item" @click="changePayType(12)">微信支付</div>
        <div class="pay-item" style="color:#999;" @click="show_pay = false">
          取消
        </div>
      </yd-popup>
      <yd-popup v-else-if="fk_show" v-model="show_pay" position="bottom">
        <div
          v-if="businessInfo.type === 1"
          class="pay-item"
          @click="changePayType(2)"
        >
          余额支付
        </div>
        <div
          v-if="
            businessInfo.type !== 3 && moneyPay == 1 && wxStatus && typeStatus
          "
          class="pay-item"
          @click="changePayType(1)"
        >
          微信支付
        </div>
        <div
          v-if="businessInfo.type === 2"
          class="pay-item"
          @click="changePayType(3)"
        >
          积分支付
        </div>
        <div
          v-if="
            businessInfo.type === 1 &&
              bid === '546e0d7595018800a6a44895274b92b1'
          "
          class="pay-item"
          @click="changePayType(5)"
        >
          预付卡支付
        </div>
        <div class="pay-item" style="color:#999;" @click="show_pay = false">
          取消
        </div>
      </yd-popup>
      <yd-popup v-else-if="!fk_show" v-model="show_pay" position="bottom">
        <div
          v-if="businessInfo.type === 1"
          class="pay-item"
          @click="changePayType(20)"
        >
          饭卡支付
        </div>

        <div
          v-if="businessInfo.type !== 3 && moneyPay == 1 && wxStatus"
          class="pay-item"
          @click="changePayType(1)"
        >
          微信支付
        </div>

        <div class="pay-item" style="color:#999;" @click="show_pay = false">
          取消
        </div>
      </yd-popup>
    </div>
    <!--选择支付方式-->

    <!-- 饭卡支付弹窗 -->
    <yd-popup position="bottom" v-model="is_pay">
      <div class="fk-info-item">
        <yd-input
          slot="right"
          v-model="mobile"
          required
          :show-success-icon="false"
          :show-error-icon="false"
          placeholder="请输入饭卡卡号"
        ></yd-input>
      </div>
      <div class="fk-info-item">
        <yd-input
          slot="right"
          type="password"
          v-model="password"
          required
          :show-success-icon="false"
          :show-error-icon="false"
          placeholder="请输入饭卡密码"
        ></yd-input>
      </div>
      <div style="margin:.2rem 0">
        <yd-button
          v-if="confirmStaus"
          class="pay-sty"
          @click.native="goPay()"
          bgcolor="#409eff"
          >确认</yd-button
        >
        <yd-button v-else class="pay-sty" bgcolor="#409eff">确认</yd-button>
      </div>
    </yd-popup>
    <!-- 饭卡支付弹窗 -->

    <!--没有支付密码弹窗-->
    <ConfirmAlert
      :value="no_pwd"
      :is_delete="false"
      :confirmText="confirmTxt"
      :confirmBtnText="confirmBtnText"
      @cancel="
        no_pwd = false;
        is_request = false;
      "
      @confirm="confirmPwd"
    ></ConfirmAlert>
    <!--没有支付密码弹窗-->

    <!--昆山支付弹窗-->
    <ConfirmAlert
      :value="kunshanConfirm"
      :is_delete="false"
      :confirmText="kunshangTxt"
      cancelBtnText="取消支付"
      confirmBtnText="确定支付"
      @cancel="kunshanCancel"
      @confirm="createOrder"
    ></ConfirmAlert>
    <!--昆山支付弹窗-->

    <!-- 弹窗 -->
    <div class="list-confirm" :style="{ display: this.has_unavailable_sty }">
      <div class="list-confirm-bk">
        <h5 v-if="has_title === 1">以下商品暂停售卖</h5>
        <h5 v-else>以下商品暂时库存不足</h5>
        <div class="unavailable-items-box">
          <div
            v-for="(item, index) in unavailable_items"
            :key="index"
            class="unavailable-items"
          >
            <img :src="item.small_pic" class="list-confirm-img" />
            <div class="content-box">
              <p class="content-list-name">{{ item.item_name }}</p>
              <div class="content-list-number">x{{ item.number }}</div>
            </div>
          </div>
        </div>
        <div class="list-confirm-btn">
          <div class="list-confirm-btnleft" @click.stop="checkBtnOK">
            知道了
          </div>
          <div class="list-confirm-btnright" @click.stop="checkBtnRemove">
            移除商品
          </div>
        </div>
      </div>
    </div>
    <!-- 弹窗 -->

    <!--安全键盘-->
    <yd-keyboard
      v-model="show_keyboard"
      :callback="checkPwd"
      disorder
      ref="keyboard"
      title
    ></yd-keyboard>
    <!--安全键盘-->

    <!--支付按钮-->
    <div class="order-btn df alc jc-sb">
      <template v-if="businessType == 2">
        <span>
          {{
            total_cash > 0 || express_fee_cash > 0
              ? `${(Number(total_cash) + express_fee_cash).toFixed(2)}元`
              : ``
          }}
          {{
            (total_cash > 0 && together_price > 0) ||
            (express_fee_cash && together_price > 0)
              ? `+`
              : ``
          }}
          {{ together_price > 0 ? `${together_price}积分` : `` }}
        </span>
      </template>
      <template v-else>
        <span>
          ¥{{ actually_price >= 0 ? together_price : express_fee.toFixed(2) }}
        </span>
      </template>
      <div
        v-if="!is_request"
        class="pay-btn"
        :class="btnClass"
        @click.stop="checkCar"
      >
        {{ btn_text }}
      </div>
      <div v-else class="pay-btn" :class="btnClass">{{ btn_text }}</div>
    </div>
  </div>
</template>

<script>
import orderGoods from "@/components/order/orderGoods";
import orderBtn from "@/components/order/orderBtn";
import card_pay from "@/components/order/card_pay";
import { orderMixin } from "@/mixins/orderMixin.js";
import CouponList from "@/components/list/CouponList";
import HeadNav from "@/components/common/HeadNav";
import { toolTip } from "@/mixins";
import ConfirmAlert from "@/components/common/ConfirmAlert";
import {
  confirmOrder,
  createOrder,
  payOrder,
  cardPayOrder,
  checkCartItems,
  getReductionList,
  cancelOrder,
  getBudgetType,
  getPayInfo
} from "@/services/orderApi";
import { getUserAddressList } from "@/services/my";
import { mapState } from "vuex";
import { getStore, isWeiXin, wxShare, randomWord } from "../../utils/common";
import { baseUrl } from "../../utils/env";
import Empty from "@/components/empty/Empty";
import md5 from "js-md5";
import setRsaPass from "@/utils/jsencrypt";
import { tripartite, isfk, payBack, customAccount } from "@/utils/custom";
import { integralPart, cashPart } from "@/utils/goodsUtil";
import { console_warn } from "../../../../pc-yiqitong/src/utils/base";
import { getOrderNotice } from "../../services/user";
let bid = window.localStorage.getItem("bid");

export default {
  name: "submit_order",
  mixins: [orderMixin, toolTip],
  components: {
    orderGoods,
    orderBtn,
    CouponList,
    ConfirmAlert,
    Empty,
    card_pay,
    HeadNav
  },
  inject: ["reload"],
  data() {
    return {
      address_info: "",
      payStatus: true, // 为修复微信浏览器事件阻断失效添加的判断参数
      addressStatus: true, // 地址是否显示
      add_type: "", //1商品详情进来 2 购物车过来 3 兑换商品
      cart_ids: "", //购物车ids
      order_type: 2, //订单类型 1 普通 2 拼团 3
      current_tab: 1, //当前优惠券tab
      show_coupons: false, //选择优惠券
      page: 1, // 开始查询的下标
      pageSize: 10, // 每次查询的数量
      coupon: 0, //优惠金额
      user_voucher_id: "", //用户优惠券id
      coupons_list: [], // 优惠券列表
      emptyList: false, // 是否为空
      tab_list: ["可使用", "不可使用"], //优惠券tab
      emp_img: require("@/assets/icon_kong_youhuiquan@3x.png"),
      show_pay: false, //选择支付方式
      no_pwd: false, //是否设置支付密码
      kunshanConfirm: false, // 昆山弹窗显隐
      is_pay: false, // 是否饭卡支付
      mobile: "", // 饭卡号
      password: "", // 饭卡密码
      pay_type: "", //支付方式
      show_keyboard: false, //支付密码键盘
      voucher_info: {}, //优惠券信息
      voucher_list: [], //优惠券列表
      not_user_list: [], //不可使用优惠券列表
      max_voucher: "", //自动选择最优优惠券
      max_marketing_info: {}, //选择的最优满减
      express_fee: null, //运费
      explode_fee_text: [], //运费分类
      goods_list: [], //商品信息
      full_reduction: 0, //满减
      const_full_reduction: 0, //静态满减，计算满减负数用到
      is_request: false, //是否在请求中
      bid: localStorage.getItem("bid"),
      show_card_pay: false, //预付卡支付弹窗
      card_no: "", //卡号
      card_password: "", //密码
      btn_text: "立即支付", //支付按钮
      recharge_account: "", //虚拟商品直充类型需要填写
      recharge_type: "", //1卡密，2直充
      is_group: 1, //1不是拼团，2是拼团
      is_kill: 1, //1不是秒杀，2是秒杀
      integralRate: localStorage.getItem("config")
        ? JSON.parse(localStorage.getItem("config")).integral_rate
        : "",
      wxStatus: null, //是否微信浏览器
      icbcHtml: null,
      confirmTxt: "", //确定框文案
      kunshangTxt: "", // 昆山支付弹窗文案
      confirmBtnTxt: "", //确定框按钮文字
      confirmType: null, //确定框类型，1为设置密码、2为确定充值账号·
      params: {}, //创建订单参数
      has_unavailable_sty: "none", //不可下单订单弹窗是否显示
      has_unavailable: true, //订单内是否有不可下单商品false无
      unavailable_items: [], //不可下单商品列表
      has_title: 0, //弹窗标题为1是不可售卖
      reduction_action: false, //满减活动弹窗
      reduction_list: [], //满减活动列表
      reduction: 0,
      realPrice: 0,
      reductionVal: false, //满减文案显示
      old_voucher_list: [], //记录接口返回回来的可使用优惠
      old_not_user_list: [], //记录接口返回回来的不可使用优惠
      jpkStatus: false, //	是否鲸品库商城
      fk_show: true,
      morePay: [], // 是否同意现金补价
      typeStatus: true, // 商品可直接微信支付状态（有积分+现金类型的商品不能使用微信支付）
      // can_pay: true,	// 是否可支付
      all_integral: false, // 商品是否只有纯积分的（针对昆山农商行）
      cash_intrgral: false, // 结算商品是否含有积分+现金类型商品
      item_active_type: this.$route.query.item_active_type,
      is_special: false, //特殊积分商城,支付不需要输入密码password默认传空
      fee_type: [], // 运费单选框val值
      old_explode_fee_key: [],
      old_explode_fee: {},
      fee_val: [],
      express_fee_cash: 0, //  运费折算现金
      confirmStaus: true, // 是否可支付
      lusse: false, // 是否分期商品
      budget_list: [], //预算类型列表
      budget_type_id: "", //选中的预算类型
      isJyFl: false, //江阴福利
      zhThrottle: false, // 中行节流
      getOrderNoticeHtml: "" // 公告html
    };
  }, //路由改变jssdk参数重新赋值
  beforeRouteEnter(to, from, next) {
    next();
    wxShare();
  },
  computed: {
    ...mapState({
      businessInfo: state => state.businessInfo,
      /* address_info: state =>
			state.create_order.address
			  ? state.create_order.address
			  : JSON.stringify(state.default_address) !== "{}"
			  ? state.default_address
			  : "", */
      user_remark: state => state.create_order.note, //备注
      settle_data: state => state.settle_data, //直接购买时结算相关参数
      exchange_data: state => state.exchange_data, //兑换商品时结算相关参数
      balance: state => state.userInfo.money, //余额
      integral: state => state.userInfo.integral, //积分
      pay_password: state => !!state.userInfo.pay_password, //是否设置支付密码
      create_order: state => state.create_order,
      businessType: state => state.businessInfo.type, //1现金商城，2积分商品，3内采商城
      moneyPay: state => state.config.money_pay, // 是否开启现金支付，1开启，0关闭
      cashToInt: state => state.config.allow_cash_supplement, // 是否开启现金补积分，1开启、0关闭
      seaStatus: state => state.seaStatus, //  海元素定制商城
      tripartite_type: state => state.tripartite_type // 三方商城类型
    }),

    // 商品总价（现金商城）
    total_price: function() {
      let num = 0;
      let self = this;
      if (self.goods_list.length) {
        self.goods_list.forEach(function(item) {
          let number = item.number,
            price = Number.parseFloat(item.price);
          self.$set(item, "subtotal", (price * number).toFixed(2));
          num += parseFloat(item.subtotal);
        });
        return num.toFixed(2);
      }
    },

    // 商品总价积分部分（积分商城）
    total_integral: function() {
      let theInt = 0;
      let self = this;
      if (self.goods_list.length) {
        self.goods_list.forEach(function(item) {
          const type = item.display_type;
          if (type !== 0 || self.tripartite_type === "VUE_APP_ZJG") {
            const number = item.number,
              price = Number.parseFloat(item.price),
              integral_usage = item.integral_usage;

            if (type === 1) {
              theInt += integralPart(type, price, 100) * number;
            } else {
              theInt += integral_usage * number;
            }
          }
        });
        return theInt;
      }
    },

    // 商品总价现金部分（积分商城）
    total_cash: function() {
      let money = 0;
      let self = this;
      if (self.goods_list.length) {
        self.goods_list.forEach(function(item) {
          const type = item.display_type;
          if (type !== 1) {
            const number = item.number,
              price = Number.parseFloat(item.price),
              integral_usage = item.integral_usage;

            if (type === 0 && self.tripartite_type !== "VUE_APP_ZJG") {
              money += price * number;
            } else {
              money += (price - cashPart(integral_usage)).toFixed(2) * number;
            }
          }
        });

        /* if (money > 0 && this.money_pay == 0) {
					self.can_pay = false;
				} */
        return money.toFixed(2);
      }
    },

    // 商品实付（现金商城）
    actually_price: function() {
      let self = this;
      let num = 0;
      if (this.item_active_type) {
        self.coupon = 0;
        self.full_reduction = 0;
      }
      if (self.total_price) {
        num +=
          Number.parseFloat(self.total_price) -
          self.coupon -
          self.full_reduction;
      }
      return num.toFixed(2);
    },

    // 积分实付（经过满减、优惠券）
    actually_integral: function() {
      const self = this;
      if (this.is_kill === 2) {
        self.coupon = 0;
        self.full_reduction = 0;
      }
      const val =
        self.total_integral - Number(self.coupon) - Number(self.full_reduction);

      // 现金+积分商城，积分实付与积分余额比较
      /* if (!self.typeStatus) {
				if (val > Number(self.integral)) {
					self.can_pay = false;
				}
			} */
      return val < 0 ? 0 : val;
    },

    // 合计
    together_price: function() {
      let self = this;
      let num = 0;
      if (self.actually_price) {
        if (self.businessType == 2) {
          //num += Number.parseFloat(self.actually_price) + Math.ceil(self.express_fee * self.integralRate);
          return self.explode_fee_text.length < 1
            ? self.actually_integral +
                Number(integralPart(1, self.express_fee, 100, 1))
            : self.actually_integral + self.express_fee;
        } else {
          num +=
            Number.parseFloat(self.actually_price) +
            Number.parseFloat(self.express_fee);
          return num.toFixed(2);
        }
      }
    },

    // 混合后微信支付
    mix_price: function() {
      let self = this;
      let mixPay = "";
      if (self.pay_type === 2) {
        if (self.together_price <= 0) {
          mixPay = Number(self.express_fee);
        } else {
          mixPay = Number(self.together_price);
        }
        let num = 0;
        if (mixPay) {
          if (Number.parseFloat(mixPay) >= Number.parseFloat(self.balance)) {
            num += Number.parseFloat(mixPay) - Number.parseFloat(self.balance);
          } else {
            num = 0;
          }
          return num.toFixed(2);
        }
      } else if (self.pay_type === 3 || self.pay_type === 10) {
        // 判断账户积分余额是否够实付积分
        /* console.log(self.together_price);
				console.log(self.integral); */
        if (Number.parseFloat(self.integral) < self.together_price) {
          // 积分余额转换成现金
          const intBalance =
            self.integral == 0 ? 0 : cashPart(self.integral).toFixed(2);
          if (Number(intBalance) > 0 && Number(intBalance) < 0.01) {
            return (
              (cashPart(self.together_price) - 0.01).toFixed(2) +
              Number(self.total_cash)
            );
          } else {
            const diff = self.together_price - self.integral;
            return (cashPart(diff) + Number(self.total_cash)).toFixed(2);
          }
        } else {
          return 0;
        }
      }
    },

    // 支付按钮状态
    /* btnClass: function () {
			const res = ['', 'ban-btn'];
			let ind = 0;

			// 是否开启现金支付
			if (this.moneyPay == 0) {
				// 结算商品是否只是纯积分类型
				this.total_cash == 0 && this.actually_integral > 0 ? ind = 0 : ind = 1;
			} else {
				// 是否开启补现金
				if (this.cashToInt == 1) {
					// 结算商品是否含积分+现金商品
					if (this.cash_intrgral) {
						this.mix_price > 0 ? ind = 1 : ind = 0;
					} else {
						this.mix_price > 0 ?
						this.morePay.length > 0 ? ind = 0 : ind = 1 :
						ind = 0;
					}
				} else {
					this.mix_price > 0 ? ind = 1 : ind = 0;
				}
			}
			return res[ind];
		} */

    btnClass: function() {
      // 不是积分商城时不用判断按钮状态
      if (this.businessType != 2) {
        return "";
      }

      const notPay1 =
          this.cashToInt == 1 && this.cash_intrgral && this.mix_price > 0, // 结算商品含积分+现金类型商品并积分余额不足
        notPay2 =
          !this.cash_intrgral && this.mix_price > 0 && this.morePay <= 0, // 结算商品不合积分+现金类型商品，积分余额不足，放弃补价
        notPay3 = this.cashToInt == 0 && this.mix_price > 0; // 关闭现金补积分，并积分余额不足

      // 是否开启现金支付
      if (this.moneyPay == 0) {
        // 结算商品是否只是纯积分类型
        if (this.total_cash == 0 && this.actually_integral > 0) {
          if (this.mix_price > 0) {
            return "ban-btn";
          } else {
            return "";
          }
        } else {
          return "ban-btn";
        }
      } else {
        if (notPay1 || notPay2 || notPay3) {
          return "ban-btn";
        } else {
          return "";
        }
      }
    }
  },
  created() {
    this.isFk();
    this.businessType == 3 ? this.getBudgetType() : null;
    this.getOrderNoticeFn();
  },
  methods: {
    async getOrderNoticeFn() {
      let res = await getOrderNotice();
      this.getOrderNoticeHtml = res.data.contents
      console.log(res, ",....");
    },
    //获取当前内采用户的预算类型
    async getBudgetType() {
      const res = await getBudgetType();
      if (res.code == 0) {
        this.budget_list = res.data;
      }
    },
    // 现金转积分
    integralPart,
    //商城判断
    customAccount,
    //饭卡商城显隐
    isFk() {
      if (process.env.VUE_APP_FK) {
        let bids = process.env.VUE_APP_FK.split(",");
        bids.forEach(val => {
          if (val == JSON.parse(getStore("businessInfo")).id) {
            this.fk_show = false;
            return;
          }
        });
      }
    },
    getParams() {
      this.add_type = this.$route.query.add_type
        ? Number.parseInt(this.$route.query.add_type)
        : "";
      this.pay_type = this.create_order.pay_type
        ? this.create_order.pay_type
        : "";
      this.recharge_account = this.create_order.recharge_account
        ? this.create_order.recharge_account
        : "";
      if (this.add_type === 1 || this.add_type === 3) {
        //1商品详情进来
        this.confirmOrder();
      } else if (this.add_type === 2) {
        //2 购物车过来
        this.cart_ids = this.$route.query.cart_ids
          ? this.$route.query.cart_ids
          : "";
        this.confirmOrder();
      }
    },

    // 检查满减显示
    /* CheckReduce() {
			let all_amount = this.coupon > this.total_price ? 0 : this.total_price - this.coupon;
			this.full_reduction = all_amount >= this.const_full_reduction ? this.const_full_reduction : 0;
			if (this.full_reduction == 0) {
				this.marketing_id = '';
			} else {
				// console.log(this.max_marketing_info)
				this.marketing_id = this.max_marketing_info.id;
			}
		}, */

    // 检查商品是否有积分加现金的
    checkDisplayType(list) {
      for (let item of list) {
        if (item.display_type === 2) {
          this.typeStatus = false;
          this.cash_intrgral = true;
          break;
        }
      }
    },

    // 检查结算商品是否只有纯积分
    checkAllGoods(list) {
      for (let item of list) {
        if (item.display_type === 1) {
          this.all_integral = true;
        } else {
          this.all_integral = false;
          break;
        }
      }
    },

    // 确认订单获取信息
    async confirmOrder() {
      let params = {};
      this.address_info = this.$store.state.create_order.address
        ? this.$store.state.create_order.address
        : JSON.stringify(this.$store.state.default_address) !== "{}"
        ? this.$store.state.default_address
        : "";
      if (this.add_type === 2) {
        params = {
          add_type: this.add_type, //购物车过来传2，商品详情进来传1
          cart_ids: this.cart_ids, //购物车id，中间,隔开
          province_id: this.address_info ? this.address_info.province_id : "" //用户地址
        };
        this.is_group = 1; //1不是拼团，2是拼团
      } else if (this.add_type === 1) {
        if (!this.settle_data) {
          this.$router.go(-1);
          return;
        }
        let obj = this.settle_data;
        this.is_group = obj.is_group; //1不是拼团，2是拼团
        params = {
          add_type: this.add_type, //购物车过来传2，商品详情进来传1
          item_id: obj.item_id, //商品id
          is_group: obj.is_group, //1不是拼团，2是拼团
          item_property_id: obj.item_property_id, //商品规格id
          number: obj.number, //数量
          integral_usage: obj.integral_usage, // 使用积分数
          limit_buy_item_id: obj.limit_buy_item_id ? obj.limit_buy_item_id : "", //秒杀商品id
          limit_buy_price: obj.limit_buy_price ? obj.limit_buy_price : "", //秒杀价格
          group_buy_item_id: obj.is_group == 2 ? obj.group_buy_item_id : "",
          group_buy_open_id: obj.is_group == 2 ? obj.group_buy_open_id : "",
          province_id: this.address_info ? this.address_info.province_id : "", //用户地址
          activity_id: this.$route.query.activity_id || 0
        };
      } else if (this.add_type === 3) {
        if (!this.exchange_data) {
          this.$router.go(-1);
          return;
        }
        let obj = this.exchange_data;
        this.is_group = 1; //1不是拼团，2是拼团
        params = {
          add_type: this.add_type, //购物车过来传2，商品详情进来传1
          item_id: obj.item_id, //商品id
          item_property_id: obj.item_property_id, //商品规格id
          number: obj.number //数量
        };
        console.log(params);
      }
      try {
        const res = await confirmOrder(params);
        if (res.code === 0) {
          const data = res.data;

          // 判断是否是秒杀商品
          if (Number(data.item_list[0].limit_buy_price) > 0) {
            this.is_kill = 2;
          }
          this.reduction_list = data.marketing_info;
          this.reduction_list.forEach(item => {
            item.status = 2;
          });

          // 判断是否分期商品
          if (data.item_list[0].display_type === 3) {
            this.lusse = true;
          }

          data.item_list.forEach(item => {
            if (item.display_type === 2) {
              this.realPrice =
                item.integral_usage * Number(item.number) + this.realPrice;
            } else if (item.display_type === 1) {
              this.realPrice =
                Number(
                  integralPart(item.display_type, Number(item.price), 100)
                ) *
                  Number(item.number) +
                this.realPrice;
            } else {
              this.realPrice =
                Number(item.price) * Number(item.number) + this.realPrice;
            }
          });

          this.goods_list = data.item_list;

          this.checkDisplayType(this.goods_list);
          this.checkAllGoods(this.goods_list);

          if (
            !this.getVirtualStatus(this.goods_list[0].source_type) ||
            this.goods_list[0].delivery_type === "pick"
          ) {
            this.addressStatus = false;
          }

          // 订单内有自营商品时节假日弹窗提示
          data.item_list.map(item => {
            // 受疫情影响暂时取消自营限制所有商品均提示（昆山商城不用提示）
            if (
              item.source_type &&
              this.pay_type != 10 &&
              this.pay_type != 21
            ) {
              if (customAccount("VUE_APP_New_JY")) {
                this.$dialog.confirm({
                  title: "兑换须知 ",
                  mes: `同事您好！为优化福利兑换体验，本次采用线上商城进行福利兑换，因方式有所不同，请您关注下列须知：<br>
                    &emsp;&emsp;①商品兑换：套餐自由选择并尽快兑换，发货前可申请退货重选（最后下单截止日期2021年9月17日）<br/>
										&emsp;&emsp;②商城余额：兑换使用余额支付，余额剩余部分不提现不退款<br/>
										&emsp;&emsp;③商品发货：行里安排统一发货<br/>
                    &emsp;&emsp;④售后问题：若商品存在质量问题，请在商城申请售后，保留快递面单、外包箱和商品<br/>
                    &emsp;&emsp;⑤人工客服工作时间：每周一至周五9:00-12:00,13:30-18:00<br/><br/>
										感谢您对本次福利兑换方式的支持&#x1F642;`,
                  opts: [
                    {
                      txt: "我知道了",
                      color: "#31BDDF"
                    }
                  ]
                });
                return false;
              }
              console.log(this.getOrderNoticeHtml,"this.getOrderNoticeHtml")
              this.$dialog.confirm({
                title: " ",
                mes: this.getOrderNoticeHtml ,
                opts: [
                  {
                    txt: "我知道了",
                    color: "#31BDDF"
                  }
                ]
              });
            }
          });
          this.express_fee = data.express_fee;
          this.voucher_info = data.voucher_info;
          this.voucher_list = data.voucher_info.voucher_list;
          this.old_voucher_list = data.voucher_info.voucher_list;
          this.not_user_list = data.voucher_info.not_user_list;
          this.old_not_user_list = data.voucher_info.not_user_list;
          this.max_voucher = data.voucher_info.max_voucher; // 自动选择最优优惠券

          //满减
          if (
            data.max_marketing_info &&
            data.max_marketing_info.constructor !== Array
          ) {
            this.max_marketing_info = data.max_marketing_info;
            this.marketing_id = data.max_marketing_info.id; //满减id
            if (this.max_marketing_info.full_type === 1) {
              this.full_reduction = this.max_marketing_info.reduce;
            } else if (this.max_marketing_info.full_price) {
              this.full_reduction = this.max_marketing_info.full_price;
            }
            let paramsAgain;
            if (this.add_type === 2) {
              paramsAgain = {
                add_type: this.add_type, // 购物车过来传2，商品详情进来传1
                marketing_id: this.marketing_id,
                cart_ids: this.cart_ids
              };
            }
            if (this.add_type === 1) {
              paramsAgain = {
                add_type: this.add_type, //购物车过来传2，商品详情进来传1
                item_id: this.settle_data.item_id || "", //商品id
                number: this.settle_data.number || "", //商品数量
                marketing_id: this.marketing_id,
                integral_usage: this.realPrice, // 直接购买积分+现金商品的积分部分
                item_property_id: this.settle_data.item_property_id //商品规格id
              };
            }
            const reductionRes = await getReductionList(paramsAgain);
            let priceList = reductionRes.data;
            priceList.forEach((item, index) => {
              if (this.businessType == 2 && !this.item_active_type) {
                this.$set(
                  this.goods_list[index],
                  "reduce_integral",
                  item.reduce_integral
                );
              }
              if (this.businessType != 2 && !this.item_active_type) {
                this.$set(
                  this.goods_list[index],
                  "reduce_integral",
                  item.reduce
                );
              }
            });
          }
          // this.dealFalseCoupon();
          if (this.current_tab === 1) {
            status = 2;
            this.coupons_list = this.voucher_list;
            this.coupons_list.forEach(a => {
              a.status = status;
            });
          } else {
            status = 6;
            this.coupons_list = this.not_user_list;
            this.coupons_list.forEach(a => {
              a.status = status;
            });
          }
          // this.CheckReduce()
          let explode_fee_text = [];
          let explode_fee = data.explode_fee;
          this.old_explode_fee = data.explode_fee;
          let keys = Object.keys(explode_fee);
          this.fee_type = keys;
          let values = Object.values(explode_fee);
          let type = "";

          // 积分商城有运费分类时初始化运费
          if (keys.length > 0 && this.businessType == 2) {
            this.express_fee = 0;
          }

          for (let i = 0; i < keys.length; i++) {
            if (this.businessType == 2) {
              if (Number(values[i] > 0)) {
                const val = integralPart(1, values[i], 100);
                explode_fee_text.push(`${type} : ${val}积分`);
                this.express_fee += Number(val);
              }
            } else {
              if (Number(values[i] > 0)) {
                type = this.getSourceTypes(parseInt(keys[i]));
                explode_fee_text.push(`${type} : ￥${values[i]}`);
              }
            }
          }
          this.explode_fee_text = explode_fee_text;

          if (this.businessType == 2) {
            this.selectType();
          }
          this.explode_fee_text.forEach((item, index) => {
            this.old_explode_fee_key.push(false);
          });

          this.selectTab(0);
          //  recharge_type
          if (
            data.item_list.length === 1 &&
            !this.getVirtualStatus(data.item_list[0].source_type)
          ) {
            this.recharge_type = data.item_list[0].recharge_type;
          }
          let shopPrice =
            this.total_integral > 0 ? this.total_integral : this.total_price;
          const newCoupon = this.old_voucher_list.filter(item => {
            return Number(item.amount_limit) <= shopPrice - this.full_reduction;
          });
          const new_not_user_list = this.voucher_list.filter(item => {
            return Number(item.amount_limit) > shopPrice - this.full_reduction;
          });
          const newArr = this.old_not_user_list.concat(new_not_user_list);
          this.not_user_list = newArr;
          this.voucher_list = newCoupon;
          this.coupons_list = this.voucher_list;
          let couponStatus = false;
          if (newCoupon.length > 0) {
            newCoupon.forEach(item => {
              if (item.id === this.max_voucher.id) {
                if (
                  !this.create_order.coupon &&
                  !this.create_order.user_voucher_id
                ) {
                  couponStatus = true;
                  this.coupon = this.max_voucher.amount
                    ? this.max_voucher.amount
                    : ""; //自动选择最优优惠券
                  this.user_voucher_id = this.max_voucher.id
                    ? this.max_voucher.id
                    : ""; //自动选择最优优惠券
                } else {
                  this.coupon = this.create_order.coupon
                    ? this.create_order.coupon
                    : ""; //已选择优惠券
                  this.user_voucher_id = this.create_order.user_voucher_id
                    ? this.create_order.user_voucher_id
                    : ""; //已选择优惠券
                  console.log(this.user_voucher_id);
                }
              } else if (!couponStatus) {
                this.coupon = 0;
              }
            });
          } else {
            this.coupon = 0;
          }
        }
      } catch (e) {}
    },
    // 选择地址
    selectAddress() {
      this.$router.push({
        name: "address",
        query: {
          name: "order"
        }
      });
    },
    //tab切换
    selectTab(index) {
      this.current_tab = index + 1;
      let status = "";
      if (this.current_tab === 1) {
        status = 2;
        this.coupons_list = this.voucher_list ? this.voucher_list : [];
        this.coupons_list.forEach(a => {
          a.status = status;
        });
      } else {
        status = 6;
        this.coupons_list = this.not_user_list ? this.not_user_list : [];
        if (this.coupons_list.length > 0) {
          this.coupons_list.forEach(a => {
            a.status = status;
          });
        }
      }
    },
    // 使用优惠券
    operate(item) {
      console.log(item);
      this.coupon = item.amount;
      this.user_voucher_id = item.id;
      this.show_coupons = false;
      let params = {
        coupon: this.coupon,
        user_voucher_id: this.user_voucher_id
      };
      this.$store.dispatch("addOrderInfo", params);
      // this.CheckReduce();
    },

    async useReduction(item) {
      this.marketing_id = item.id;
      this.reductionVal = false;
      this.full_reduction = item.full_price;
      this.reduction_action = false;
      let params;
      if (this.add_type === 2) {
        params = {
          add_type: this.add_type, //购物车过来传2，商品详情进来传1
          marketing_id: item.id,
          cart_ids: this.cart_ids
        };
      }
      if (this.add_type === 1) {
        params = {
          add_type: this.add_type, //购物车过来传2，商品详情进来传1
          item_id: this.settle_data.item_id || "", //商品id
          number: this.settle_data.number || "", //商品数量
          marketing_id: item.id,
          item_property_id: this.settle_data.item_property_id //商品规格id
        };
      }
      const result = await getReductionList(params);
      let priceList = result.data;
      priceList.forEach((item, index) => {
        if (this.businessType == 2) {
          this.$set(
            this.goods_list[index],
            "reduce_integral",
            item.reduce_integral
          );
        } else {
          this.$set(this.goods_list[index], "reduce_integral", item.reduce);
        }
      });
      // this.dealFalseCoupon();
      let shopPrice =
        this.total_integral > 0 ? this.total_integral : this.total_price;
      const newCoupon = this.old_voucher_list.filter(item => {
        return Number(item.amount_limit) < shopPrice - this.full_reduction;
      });
      const new_not_user_list = this.voucher_list.filter(item => {
        return Number(item.amount_limit) > shopPrice - this.full_reduction;
      });
      const newArr = this.not_user_list.concat(new_not_user_list);
      this.not_user_list = newArr;
      this.voucher_list = newCoupon;
      newCoupon.forEach(item => {
        if (item.id === this.max_voucher.id) {
          if (!this.create_order.coupon && !this.create_order.user_voucher_id) {
            this.coupon = this.max_voucher.amount
              ? this.max_voucher.amount
              : ""; //自动选择最优优惠券
            this.user_voucher_id = this.max_voucher.id
              ? this.max_voucher.id
              : ""; //自动选择最优优惠券
          } else {
            this.coupon = this.create_order.coupon
              ? this.create_order.coupon
              : ""; //已选择优惠券
            this.user_voucher_id = this.create_order.user_voucher_id
              ? this.create_order.user_voucher_id
              : ""; //已选择优惠券
            console.log(this.user_voucher_id);
          }
        }
      });
      if (this.current_tab === 1) {
        status = 2;
        this.coupons_list = this.voucher_list;
        this.coupons_list.forEach(a => {
          a.status = status;
        });
      } else {
        status = 6;
        this.coupons_list = this.not_user_list;
        this.coupons_list.forEach(a => {
          a.status = status;
        });
      }
    },
    // 不使用优惠券
    noUseCoupons() {
      this.coupon = "";
      this.show_coupons = false;
      this.user_voucher_id = "";
      // this.CheckReduce()
    },
    // 不使用满减
    noUseReduction() {
      this.full_reduction = "";
      this.marketing_id = "";
      this.reduction_action = false;
      this.reductionVal = true;
      this.goods_list.forEach(item => {
        this.$set(item, "reduce_integral", 0);
      });
      if (!this.create_order.coupon && !this.create_order.user_voucher_id) {
        this.coupon = this.max_voucher.amount ? this.max_voucher.amount : ""; //自动选择最优优惠券
        this.user_voucher_id = this.max_voucher.id ? this.max_voucher.id : ""; //自动选择最优优惠券
      } else {
        this.coupon = this.create_order.coupon ? this.create_order.coupon : ""; //已选择优惠券
        this.user_voucher_id = this.create_order.user_voucher_id
          ? this.create_order.user_voucher_id
          : ""; //已选择优惠券
        console.log(this.user_voucher_id);
      }
      this.voucher_list = this.old_voucher_list;
      this.not_user_list = this.old_not_user_list;
      if (this.current_tab === 1) {
        status = 2;
        this.coupons_list = this.voucher_list;
        this.coupons_list.forEach(a => {
          a.status = Number(status);
        });
      } else {
        status = 6;
        this.coupons_list = this.not_user_list;
        this.coupons_list.forEach(a => {
          a.status = Number(status);
        });
      }
    },
    // 订单备注
    toOrderNote() {
      if (this.recharge_account)
        this.$store.dispatch("addOrderInfo", {
          recharge_account: this.recharge_account
        });
      this.$router.push({
        name: "order_note"
      });
    },
    //选择支付方式
    changePayType(e) {
      console.log(e);
      this.pay_type = e;
      this.show_pay = false;
      this.$store.dispatch("addOrderInfo", { pay_type: this.pay_type });
    },
    //不可购买订单弹窗-我知道了
    checkBtnOK() {
      this.has_unavailable_sty = "none";
    },
    //不可购买订单弹窗-移除订单
    async checkBtnRemove() {
      this.has_unavailable_sty = "none";
      let id = this.unavailable_items.map(item => {
        return item.id.toString();
      });
      let cart = this.$route.query.cart_ids.split(",");
      let cart_ids = cart.filter(item => id.indexOf(item) == -1).join(",");
      let that = this;
      console.log(cart_ids, id);
      //选择移除时更新当前页面的cart_ids
      if (cart_ids) {
        this.$router.replace(
          {
            name: "submit_order",
            query: {
              cart_ids,
              add_type: this.$route.query.add_type
            }
          },
          () => {
            this.reload();
          }
        );
      } else {
        this.$router.go(-1);
        this.reload();
      }
    },
    async checkCar() {
      /* 支付按钮不能点击 */
      if (this.btnClass === "ban-btn") {
        return;
      }
      /* 支付按钮不能点击 */

      //提交订单检测购物车商品
      let para = {
        cart_ids: this.cart_ids,
        user_address_id: this.address_info ? this.address_info.id : ""
      };
      if (this.$route.query.add_type == 2) {
        const res = await checkCartItems(para);
        res.data.has_unavailable ? (this.has_unavailable_sty = "block") : null;
        if (res.code === 0) {
          (this.has_unavailable = res.data.has_unavailable),
            (this.unavailable_items = res.data.unavailable_items || []);
          !res.data.has_unavailable ? this.createOrder() : null;
        } else if (res.code === -1) {
          this.$dialog.toast({
            mes: res.msg
          });
        } else {
          this.$dialog.toast({
            mes: "请稍后再试"
          });
        }
      } else {
        if (this.pay_type === 10 && this.all_integral && this.mix_price == 0) {
          if (this.recharge_type == 2 && !this.recharge_account) {
            this.$dialog.toast({
              mes: "请填写充值账号，如手机号、QQ号、油卡号等"
            });
            this.is_request = false;
            return;
          }
          // 昆山纯积分商品结算弹窗
          this.kunshangTxt = `该订单需要支付${this.together_price}积分`;
          this.kunshanConfirm = true;
        } else {
          this.createOrder();
        }
      }
    },

    // 昆山弹窗取消支付
    kunshanCancel() {
      this.kunshanConfirm = false;
      this.is_request = false;
    },

    // 中行调起支付控件
    async callPaymentControl(order_sn) {
      try {
        // 中行节流
        if (this.zhThrottle) {
          return false;
        }
        this.zhThrottle = true;
        let self = this;
        let res = await getPayInfo(order_sn);
        if (res.code === 0) {
          let setting = res.data.data;
          window.c_plugins.merchantBridge.callPaymentControl(
            function(data) {
              self.zhThrottle = false;
              // 下述内容为点击左上角<后执行
              if (data.isCancelPay === "1") {
                // 客户取消了支付
              } else {
                if (data.orderStatus === "1") {
                  // 支付成功的回调方法，可写返回后逻辑
                  self.is_request = false;
                  console.log("res我是回调成功了", res);
                  self.$router.replace({
                    name: "pay_success",
                    query: {
                      id: self.pay_order_id,
                      money: self.together_price,
                      group_id: self.group_id ? self.group_id : ""
                    }
                  });
                } else {
                  // 支付失败的回调方法 ，可写返回后逻辑
                  self.is_request = false;
                  self.$router.replace({
                    name: "order-collect",
                    query: {
                      pay_order_id: self.pay_order_id,
                      haveDone: 0
                    }
                  });
                }
              }
            },
            function(err) {
              alert(err.message || err || "网络错误，请检查网络连接");
            },
            setting
          );
        } else {
          this.$dialog.toast({
            mes: "唤起支付异常，请再次尝试",
            timeout: 1500
          });
          setTimeout(() => {
            this.$router.push({
              path: "/order_list",
              query: {
                key: "PAY"
              }
            });
          }, 1500);
        }
      } catch (e) {}
    },

    //提交订单
    async createOrder() {
      let para = {
        cart_ids: this.cart_ids,
        user_address_id: this.address_info ? this.address_info.id : ""
      };
      if (this.kunshanConfirm) {
        this.kunshanConfirm = false;
      }
      this.params = {};
      const startRandom = randomWord(false, 4);
      const endRandom = randomWord(false, 4);
      if (this.add_type === 2) {
        const sign = `${startRandom}cart_ids:${this.cart_ids}${endRandom}`;
        this.params = {
          user_voucher_id: this.user_voucher_id, //用户优惠券id
          marketing_id: this.marketing_id ? this.marketing_id : "", //满减id
          add_type: this.add_type, //购物车过来传2，商品详情进来传1
          cart_ids: this.cart_ids, //购物车ids
          limit_buy_item_id: "", //秒杀活动id
          is_group: 0, //0非团购，1参与拼团，2开团
          user_address_id: this.address_info ? this.address_info.id : "", //用户地址
          type: this.businessInfo.type, //1现金商城2积分商城3内采商城
          user_remark: this.user_remark, //备注
          payway: this.pay_type,
          fee_pay_way: JSON.stringify(this.feeList),
          budget_type_id: this.budget_type_id,
          sign: md5(sign),
          random: `${startRandom}${endRandom}`,
          mobile: true,
          activity_id: this.$route.query.activity_id || 0
        };
      } else if (this.add_type === 1) {
        let obj = this.settle_data;
        let is_group = ""; //0非团购，1参与拼团，2开团
        if (obj.group_buy_item_id) is_group = 2;
        else if (obj.group_buy_open_id) is_group = 1;
        else is_group = 0;
        // this.group_id = obj.group_buy_open_id ? obj.group_buy_open_id : (obj.group_buy_item_id ? obj.group_buy_item_id : '');

        const sign = `${startRandom}item_id:${obj.item_id}${endRandom}`;
        this.params = {
          item_id: obj.item_id, //商品id
          item_property_id: obj.item_property_id, //商品规格id
          number: obj.number, //数量
          user_voucher_id: this.user_voucher_id, //用户优惠券id
          marketing_id: this.marketing_id ? this.marketing_id : "", //满减id
          add_type: this.add_type, //购物车过来传2，商品详情进来传1
          cart_ids: this.cart_ids, //购物车ids
          limit_buy_item_id: obj.limit_buy_item_id ? obj.limit_buy_item_id : "", //秒杀商品id
          is_group: is_group, //0非团购，1参与拼团，2开团
          group_buy_open_id: obj.group_buy_open_id ? obj.group_buy_open_id : "", //参与拼团需要传团id
          group_buy_item_id: obj.group_buy_item_id ? obj.group_buy_item_id : "", //开团传的商品团id
          user_address_id: this.address_info ? this.address_info.id : "", //用户地址
          type: this.businessInfo.type, //1现金商城2积分商城3内采商城
          user_remark: this.user_remark, //备注
          payway: this.pay_type,
          recharge_account: this.recharge_account ? this.recharge_account : "",
          integral_usage: this.settle_data.integral_usage, // 积分+现金用户选择的积分使用值
          fee_pay_way: JSON.stringify(this.feeList),
          budget_type_id: this.budget_type_id,
          sign: md5(sign),
          random: `${startRandom}${endRandom}`,
          mobile: true,
          activity_id: this.$route.query.activity_id || 0
        };
      } else if (this.add_type === 3) {
        // console.log('3')
        let obj = this.exchange_data;

        const sign = `${startRandom}item_id:${obj.item_id}${endRandom}`;
        this.params = {
          item_id: obj.item_id, //商品id
          item_property_id: obj.item_property_id, //商品规格id
          is_group: 0, //0非团购，1参与拼团，2开团
          number: obj.number, //数量
          add_type: this.add_type, //购物车过来传2，商品详情进来传1 3兑换商品
          user_address_id: this.address_info ? this.address_info.id : "", //用户地址
          type: this.businessInfo.type, //1现金商城2积分商城3内采商城
          user_remark: this.user_remark, //备注
          payway: 4, //1现金，2余额，3积分 4兑换
          card_ticket_id: getStore("card_ticket_id") || "",
          budget_type_id: this.budget_type_id,
          sign: md5(sign),
          random: `${startRandom}${endRandom}`,
          mobile: true
        };
      }
      // checkCartItems(para)
      this.is_request = true;
      if (!this.address_info && !this.recharge_type && this.addressStatus) {
        this.$dialog.toast({
          mes: "请选择收货地址"
        });
        this.is_request = false;
        return;
      }
      if (this.businessType == 3 && !this.budget_type_id) {
        this.$dialog.toast({
          mes: "请选择预算类型"
        });
        this.is_request = false;
        return;
      }
      if (this.recharge_type == 2 && !this.recharge_account) {
        this.$dialog.toast({
          mes: "请填写充值账号，如手机号、QQ号、油卡号等"
        });
        this.is_request = false;
        return;
      }

      if (this.add_type === 1 && !this.settle_data) {
        this.is_request = false;
        return;
      }
      //特殊积分商城无密码下单
      if (
        this.pay_type == 3 &&
        (this.businessInfo.id == 2311 ||
          this.businessInfo.id == 2265 ||
          tripartite("VUE_APP_GUANGDALOGIN") ||
          customAccount("VUE_APP_CHAOWEI"))
      ) {
        if (
          tripartite("VUE_APP_GUANGDALOGIN") ||
          customAccount("VUE_APP_CHAOWEI")
        ) {
          this.no_pwd = true;
          this.confirmTxt = "是否确认兑换?";
          return false;
        }
        this.is_special = true;
        this.toCreateOrder(this.params);
        return;
      }
      if (this.add_type === 3 && !this.exchange_data) {
        this.is_request = false;
        return;
      }
      // 中行无密码下单
      if (this.pay_type == 21) {
        this.toCreateOrder(this.params);
        return;
      }

      if (
        (!this.pay_password && (this.pay_type == 2 || this.pay_type == 3)) ||
        (!this.pay_password && this.businessInfo.type == 3)
      ) {
        //未设置支付密码
        this.no_pwd = true;
        this.is_request = false;
        this.confirmType = 1;
        this.confirmTxt = "抱歉，您还没有设置支付密码";
        this.confirmBtnText = "去设置";
        return;
      }

      if (this.businessInfo.type !== 3) {
        if (!this.params.payway && this.add_type !== 3) {
          this.$dialog.toast({
            mes: "请选择支付方式"
          });
          this.is_request = false;
          return;
        }
      }
      if (this.item_active_type) {
        this.params.marketing_id = "";
        this.params.user_voucher_id = "";
      }
      //直充二次确认
      if (this.recharge_type == 2) {
        this.no_pwd = true;
        this.is_request = false;
        this.confirmType = 2;
        this.confirmTxt = `您将充值的账号为${this.recharge_account}，一旦提交将无法修改`;
        this.confirmBtnText = "确定";
      } else {
        this.toCreateOrder(this.params);
      }
    },

    // 饭卡支付
    async goPay() {
      this.confirmStaus = false;
      if (this.password && this.mobile) {
        let password = /^[0-9a-zA-Z]*$/g.test(this.password);
        let mobile = /^[0-9a-zA-Z]*$/g.test(this.mobile);
        if (!password || !mobile) {
          this.$dialog.toast(
            {
              mes: "仅允许输入数字或字母,请重新输入!"
            },
            600
          );
          this.mobile = "";
          this.password = "";
          this.$router.replace({
            name: "order-collect",
            query: {
              pay_order_id: this.pay_order_id,
              haveDone: 0
            }
          });
          return;
        }
        // this.is_pay = false
        let params = {
          pay_order_id: this.pay_order_id,
          password: this.password,
          mobile: this.mobile
        };
        const res = await cardPayOrder(params);
        if (res.code != 0) {
          this.$dialog.toast(
            {
              mes: res.msg
            },
            300
          );
          this.$router.replace({
            name: "order-collect",
            query: {
              pay_order_id: this.pay_order_id,
              haveDone: 0
            }
          });
        } else {
          this.$router.replace({
            name: "pay_success",
            query: {
              id: this.pay_order_id,
              money: this.together_price,
              group_id: this.group_id ? this.group_id : ""
            }
          });
        }
      } else {
        this.$dialog.toast(
          {
            mes: "请填写账号密码"
          },
          300
        );
      }
      setTimeout(() => {
        this.confirmStaus = true;
      }, 2000);
    },

    //创建订单
    async toCreateOrder(params) {
      if (this.payStatus) {
        this.kunshanConfirm = false;
        this.payStatus = false;
        this.$dialog.loading.open("订单提交中");
        const res = await createOrder(params);
        this.$dialog.loading.close();
        if (res.code === 0) {
          const data = res.data;
          // console.log(data);
          this.pay_order_id = data.pay_order_id;
          this.group_id = data.group_buy_open_id;
          // 跳转鲸品库支付
          if (this.jpkStatus && !this.wxStatus) {
            let title =
              this.goods_list.length < 2
                ? ""
                : `等${this.goods_list.length}件商品`;
            let way = this.pay_type === 11 ? 1 : 2;
            document.location = `yqtjs://h5_goods_pay?order_title=${this.goods_list[0].item_name}${title}&order_no=${data.pay_order_id}
						&pay_order_sn=${data.pay_order_sn}&pay_source=1&pay_type=${way}&total_fee=${this.together_price}
						&notify_url=${process.env.VUE_APP_JPKURL}`;
            return;
          }

          // 百兑通处理
          /* if (this.pay_type === 15) {
						if (Number(this.total_cash) > 0 || Number(this.express_fee_cash) > 0) {
							console.log('混合支付');
						} else {
							console.log('纯支付');
						}
					} */

          if (this.pay_type === 5) {
            // 预付卡支付
            this.toShowCardPay();
          } else if (this.pay_type === 21) {
            // 中行支付
            this.callPaymentControl(data.pay_order_sn);
          } else if (
            this.pay_type === 10 &&
            this.all_integral &&
            this.mix_price == 0
          ) {
            this.payOrder(data.pay_order_id, 123);
          } else if (
            this.pay_type === 7 ||
            this.pay_type === 10 ||
            this.pay_type === 13 ||
            this.pay_type === 15 ||
            this.pay_type === 16
          ) {
            // 三方支付
            this.payOrder(data.pay_order_id);
          } else if (this.mix_price > 0 && this.add_type !== 3) {
            //混合支付->微信支付
            this.payOrder(data.pay_order_id);
          } else if (
            this.pay_type === 3 &&
            (Number(this.total_cash) > 0 || Number(this.express_fee_cash) > 0)
          ) {
            // 积分商城含有现金部分，混合支付
            this.payOrder(data.pay_order_id);
          } else if (
            this.pay_type === 3 &&
            (this.businessInfo.id == 2311 ||
              this.businessInfo.id == 2265 ||
              tripartite("VUE_APP_GUANGDALOGIN") ||
              customAccount("VUE_APP_CHAOWEI"))
          ) {
            if (
              tripartite("VUE_APP_GUANGDALOGIN") ||
              customAccount("VUE_APP_CHAOWEI")
            ) {
              this.payOrder(this.pay_order_id, "");
              return false;
            }

            //特殊积分商城支付不需要输入支付密码
            this.$dialog.confirm({
              title: "请核对您的支付信息",
              mes: `您将消耗：${this.together_price}积分
							</br>`,
              opts: [
                {
                  txt: "取消",
                  color: "#353535",
                  callback: () => {
                    this.$router.replace({
                      name: "order-collect",
                      query: {
                        pay_order_id: this.pay_order_id,
                        haveDone: 0
                      }
                    });
                  }
                },
                {
                  txt: "确定",
                  color: true,
                  callback: async () => {
                    this.payOrder(this.pay_order_id, "");
                  }
                }
              ]
            });
          } else if (this.pay_password && this.pay_type != 1) {
            //输入支付密码
            this.toPayPwd();
          } else {
            //检测商品是否可售卖
            if (res.code === -2) {
              this.has_unavailable_sty = "block";
              this.has_unavailable = true;
              this.unavailable_items = res.data;
              this.has_title = 1;
            }
            this.is_request = false;
            this.payStatus = true;
          }
        } else if (res.code === -1 && this.$route.query.item_active_type) {
          this.$router.push({
            path: "goods-detail",
            query: {
              item_id: this.goods_list[0].id,
              item_active_type: this.$route.query.item_active_type
            }
          });
        } else if (res.code == -2) {
          this.has_unavailable_sty = "block";
          this.has_unavailable = true;
          this.unavailable_items = res.data;
          this.has_title = 1;
          this.is_request = false;
          this.payStatus = true;
        }
      }
      if (this.pay_type == 20) {
        this.is_pay = true;
        this.is_request = false;
      } else if (this.pay_type === 1) {
        //微信支付
        this.payOrder(this.pay_order_id);
      }
    },

    toShowCardPay() {
      this.show_card_pay = true;
    },

    //  预付卡支付
    async cardPayOrder(item) {
      let params = {
        pay_order_id: this.pay_order_id,
        card_no: item.card_no,
        password: item.card_password
      };
      console.log(params);
      try {
        const res = await cardPayOrder(params);
        if (res.code === 0) {
          this.show_card_pay = false;
          // window.location.replace(`${baseUrl}/?bid=${bid}#/pay_success?id=${this.pay_order_id}&money=${this.together_price}&group_id=${this.group_id ? this.group_id : ''}`);
          this.$router.replace({
            name: "pay_success",
            query: {
              id: this.pay_order_id,
              money: this.together_price,
              group_id: this.group_id ? this.group_id : ""
            }
          });
        } else {
          this.$dialog.toast({
            mes: res.msg
          });
          this.$router.replace({
            name: "order-collect",
            query: {
              pay_order_id: this.pay_order_id,
              haveDone: 0
            }
          });
        }
      } catch (e) {}
    },

    //支付
    async payOrder(id, val) {
      try {
        const startRandom = randomWord(false, 4);
        const endRandom = randomWord(false, 4);
        const sign = `${startRandom}pay_order_id:${id}${endRandom}`;
        const params = {
          pay_order_id: id,
          password: this.isJyFl ? setRsaPass(val) : val,
          sign: md5(sign),
          random: `${startRandom}${endRandom}`,
          mobile: true
        };
        const res = await payOrder(params);
        if (res.code === 0) {
          const data = res.data;
          const self = this;
          let total_cash = Number.parseFloat(this.total_cash);

          //特殊积分商城支付无需密码
          if (
            (this.businessInfo.id == 2311 ||
              this.businessInfo.id == 2265 ||
              tripartite("VUE_APP_GUANGDALOGIN") ||
              customAccount("VUE_APP_CHAOWEI")) &&
            this.pay_type == 3 &&
            !this.cash_intrgral &&
            total_cash == 0 &&
            this.morePay.length == 0
          ) {
            self.$dialog.loading.close();
            // window.location.replace(`${baseUrl}/?bid=${bid}#/pay_success?id=${self.pay_order_id}&money=${self.together_price}&group_id=${self.group_id ? self.group_id : ''}`);
            // window.location.replace(`${baseUrl}/?bid=${bid}#/pay_success?id=${self.pay_order_id}&money=0`);
            self.$router.replace({
              name: "pay_success",
              query: {
                id: self.pay_order_id,
                money: self.together_price,
                group_id: self.group_id ? self.group_id : ""
              }
            });
          } else if (!val) {
            console.log("微信支付");
            //微信支付失败提示
            if (!data.timestamp) {
              await cancelOrder(this.pay_order_id);
              this.$dialog.toast(
                {
                  mes: "下单失败已取消，请确认您是否在微信支付环境"
                },
                600
              );
              setTimeout(() => {
                this.$router.replace({
                  name: "home"
                });
              }, 1000);
              return;
            }
            self.is_request = false;
            wx.chooseWXPay({
              timestamp: data.timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
              nonceStr: data.nonceStr, // 支付签名随机串，不长于 32 位
              package: data.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
              signType: data.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
              paySign: data.paySign, // 支付签名
              success: function(res) {
                // this.toPay()
                self.is_request = false;
                console.log("res我是回调成功了", res);
                // window.location.replace(`${baseUrl}/?bid=${bid}#/pay_success?id=${self.pay_order_id}&money=${self.together_price}&group_id=${self.group_id ? self.group_id : ''}`);
                self.$router.replace({
                  name: "pay_success",
                  query: {
                    id: self.pay_order_id,
                    money: self.together_price,
                    group_id: self.group_id ? self.group_id : ""
                  }
                });
              },
              fail: function(res) {
                self.is_request = false;
                self.$router.replace({
                  name: "order-collect",
                  query: {
                    pay_order_id: self.pay_order_id,
                    haveDone: 0
                  }
                });
              },
              cancel: function(res) {
                self.is_request = false;
                self.$router.replace({
                  name: "order-collect",
                  query: {
                    pay_order_id: self.pay_order_id,
                    haveDone: 0
                  }
                });
              }
            });
          } else {
            self.$dialog.loading.close();
            // window.location.replace(`${baseUrl}/?bid=${bid}#/pay_success?id=${self.pay_order_id}&money=${self.together_price}&group_id=${self.group_id ? self.group_id : ''}`);
            // window.location.replace(`${baseUrl}/?bid=${bid}#/pay_success?id=${self.pay_order_id}&money=0`);
            self.$router.replace({
              name: "pay_success",
              query: {
                id: self.pay_order_id,
                money: self.together_price,
                group_id: self.group_id ? self.group_id : ""
              }
            });
          }
        } else if (res.code === 2) {
          this.icbcHtml = res.data.split("<script>")[0];
          this.$nextTick(() => {
            document.forms[0].submit();
          });
        } else if (res.code === 10) {
          // 昆山农商行回调
          if (process.env.NODE_ENV === "intergal") {
            window.location.href = `${process.env.VUE_APP_KSFENQI}${res.data.trade_no}`;
          } else {
            window.location.href = `${process.env.VUE_APP_KUNSHANURL}${res.data.trade_no}`;
          }
        } else if (res.code === 13) {
          // 拓本成功跳转
          window.location.href = res.data.url;
        } else if (res.code === 15) {
          // 百兑通回调
          this.$router.replace({
            name: "pay_success",
            query: {
              id: this.pay_order_id,
              money: this.together_price,
              group_id: this.group_id ? this.group_id : ""
            }
          });
        } else if (res.code === 16) {
          payBack(res.code, this.pay_order_id, res.data.url);
        } else {
          this.payStatus = true;
          if (val) {
            this.$dialog.loading.close();
            this.$refs.keyboard.$emit("ydui.keyboard.error", res.msg);
          } else {
            this.$dialog.toast({
              mes: res.msg
            });
          }
        }
      } catch (e) {
        console_warn(e);
      }
    },

    //去设置支付密码，确认充值账号
    confirmPwd() {
      if (this.confirmType === 1) {
        this.$router.push({
          name: "verify_phone",
          query: {
            function_type: 1
          }
        });
      } else {
        this.no_pwd = false;
        this.is_request = true;
        this.toCreateOrder(this.params);
      }
    },
    //支付密码
    toPayPwd() {
      this.show_keyboard = true;
      this.is_request = false;
    },
    // 验证支付密码
    checkPwd(val) {
      // console.log("输入的密码是：" + val + " - 无序");
      this.$dialog.loading.open("验证支付密码");
      this.payOrder(this.pay_order_id, md5(val));
    },

    // 工行支付
    icbcPay() {
      if (process.env.VUE_APP_BIDS) {
        let bids = process.env.VUE_APP_BIDS.split(",");
        for (let val of bids) {
          if (val == JSON.parse(getStore("businessInfo")).id) {
            this.pay_type = 7;
            break;
          }
        }
      }
    },

    // 百兑通
    bdtPay() {
      if (tripartite("VUE_APP_BDT")) {
        this.pay_type = 15;
      }
    },

    // 昆山农商行
    kunshanPay() {
      if (tripartite("VUE_APP_KUNSHAN")) {
        this.pay_type = 10;
      }
    },

    // 鲸品库商城判断
    isJpk() {
      if (tripartite("VUE_APP_JPKID")) {
        this.jpkStatus = true;
      }
    },

    // 广西拓本商城
    tabenStatus() {
      if (tripartite("VUE_APP_TABEN")) {
        this.pay_type = 13;
      }
    },

    // 张家港判断
    zjgStatus() {
      if (this.tripartite_type === "VUE_APP_ZJG") {
        this.pay_type = 16;
      }
    },

    // 中行判断
    zgyhStatus() {
      if (this.tripartite_type === "VUE_APP_ZHONGHANG") {
        this.pay_type = 21;
      }
    },

    //江阴福利
    isjy() {
      if (customAccount("VUE_APP_JY")) {
        this.isJyFl = true;
      }
    },

    //处理不合适的优惠券
    dealFalseCoupon() {
      if (
        this.realPrice - this.full_reduction < this.coupon &&
        this.full_reduction
      ) {
        this.coupon = "";
        const new_voucher_list = this.voucher_list.filter(item => {
          return (
            item.id !== this.max_voucher.id &&
            Number(item.amount_limit) < this.realPrice - this.full_reduction
          );
        });
        const new_not_user_list = this.voucher_list.filter(item => {
          return (
            Number(item.amount_limit) > this.realPrice - this.full_reduction
          );
        });
        const newArr = this.not_user_list.concat(new_not_user_list);
        this.not_user_list = newArr;
        this.voucher_list = new_voucher_list;
      }
    },

    // 没设默认地址，但有地址时收货地址显示
    async getAddress() {
      this.address_info = this.$store.state.create_order.address
        ? this.$store.state.create_order.address
        : JSON.stringify(this.$store.state.default_address) !== "{}"
        ? this.$store.state.default_address
        : "";
      if (!this.address_info) {
        let params = {
          page: 1,
          listRows: 3
        };
        try {
          const res = await getUserAddressList(params);
          if (res.code === 0) {
            this.address_info = res.data.list[0];
            // console.log(this.address_info);
          }
        } catch (e) {}
      }
    },

    // 运费支付方式
    selectType() {
      let feeObj = {};
      this.$nextTick(() => {
        this.old_explode_fee_key.forEach((item, index) => {
          let keys = this.fee_type[index];
          let val = item;
          feeObj[keys] = val;
        });
        this.feeList = feeObj;
        let keys = Object.keys(this.old_explode_fee);
        let values = Object.values(this.old_explode_fee);
        let explode_fee_text = [];
        let type = "";

        // 积分商城有运费分类时初始化运费
        if (keys.length > 0 && this.businessType == 2) {
          this.express_fee = 0;
          this.express_fee_cash = 0;
        }
        for (let i = 0; i < keys.length; i++) {
          type = this.getSourceTypes(parseInt(keys[i]));
          if (this.businessType == 2) {
            if (this.old_explode_fee_key[i]) {
              if (Number(values[i] > 0)) {
                explode_fee_text.push(`${type} : ${values[i]}元`);
                this.express_fee_cash += Number(values[i]);
              }
            } else {
              if (Number(values[i] > 0)) {
                const val = integralPart(1, values[i], 100);
                explode_fee_text.push(`${type} : ${val}积分`);
                this.express_fee += Number(val);
              }
            }
          }
        }
        this.explode_fee_text = explode_fee_text;
      });
    },

    show_payCheck() {
      setTimeout(() => {
        this.show_pay = true;
        window.scrollBy(0, 1);
      }, 500);
    }
  },
  mounted() {
    this.wxStatus = isWeiXin();
    this.getParams();
    this.getAddress();
    document.title = "订单确认";
    if (this.businessInfo.type !== 3) {
      this.btn_text =
        tripartite("VUE_APP_TABEN") ||
        tripartite("VUE_APP_GUANGDALOGIN") ||
        customAccount("VUE_APP_CHAOWEI")
          ? "立即兑换"
          : "立即支付";
    } else {
      this.btn_text = "立即提交";
    }
    this.icbcPay();
    this.kunshanPay();
    this.isJpk();
    this.tabenStatus();
    this.bdtPay();
    this.zjgStatus();
    this.zgyhStatus();
    this.isjy();
    // payBack(16, 2312);
    // this.pay_type = tripartitePayType(JSON.parse(getStore("businessInfo")).id);
    window["yqt_pay_order_status_get"] = (
      pay_status,
      order_no,
      pay_type,
      phone_type
    ) => {
      //alert(`支付状态${pay_status}，订单编号${order_no}，支付方式${pay_type}，手机客户端${phone_type}`);
      location.reload();
    };
  },
  watch: {
    show_keyboard(val) {
      let self = this;
      if (!val && self.pay_order_id) {
        if (this.seaStatus) {
          self.$router.replace({
            name: "exchange_detail",
            query: {
              pay_order_id: self.pay_order_id,
              order_status: 0
            }
          });
        } else {
          self.$router.replace({
            name: "order-collect",
            query: {
              pay_order_id: self.pay_order_id,
              haveDone: 0
            }
          });
        }
      }
    },
    is_pay(val) {
      if (!val && isfk()) {
        this.$router.replace({
          name: "order-collect",
          query: {
            pay_order_id: this.pay_order_id,
            haveDone: 0
          }
        });
      }
    }
  }
};
</script>
<style lang="less">
@import "../../common/less/variable.less";
@import "../../common/less/mixin.less";

.yd-confirm-bd {
  margin-top: 0.2rem;
  height: 260px;
  overflow: auto;
}

.submit-order {
  padding-bottom: 1rem;
  box-sizing: border-box;
  background: #fbfbfb;
  height: 100%;
  .fk-info-item {
    .sc(0.32rem, #333);
    background: @color-white;
    .wh(100%, 1rem);
    padding: 0 0.24rem;
    margin-top: 0.2rem;

    input::placeholder {
      .sc(0.32rem, @color-light-grey);
    }
  }
  .pay-sty {
    width: 60%;
    height: 80%;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 1rem;
    line-height: 0.88rem;
    font-size: 0.32rem;
    color: #fff;
    text-align: center;
    border: 0;
  }
  .pay-stys {
    width: 60%;
    margin: 0 20% 0.2rem;
    background: #409eff;
    border-radius: 1rem;
    line-height: 0.88rem;
    font-size: 0.32rem;
    color: #fff;
    text-align: center;
    border: 0;
  }

  .order-top {
    width: 100%;
    height: 1rem;
    background: var(--main-color);
    padding: 0 0.3rem 0 0.24rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .top-left {
      img {
        width: 0.48rem;
        height: 0.48rem;
        margin-right: 6px;
      }

      display: flex;
      align-items: center;
      font-size: 0.3rem;
      color: #fff;
    }

    .top-right {
      color: #fff;
      font-size: 0.3rem;
    }
  }

  .order-address {
    min-height: 1.6rem;
    position: relative;
    padding: 0.36rem 1rem 0.36rem 0.3rem;
    box-sizing: border-box;
    line-height: 0.4rem;
    background: white url(../../assets/ic_jinru_right_black@3x.png) no-repeat
      92% 50%;
    background-size: 0.36rem 0.36rem;

    .user-info {
      font-size: 0.4rem;
      color: @color-dark-grey;
      margin-bottom: 3px;

      .user-name {
        margin-right: 0.26rem;
      }
    }

    .user-address {
      font-size: 0.22rem;
      color: #666666;
    }

    /*无收货地址*/

    .no-address {
      .sc(0.4rem, @color-dark-grey);
      line-height: 0.88rem;
      font-weight: 500;
    }

    .order-line {
      height: 3px;
      width: 100%;
      background: url("~@/assets/img_dingdan_tiaoxiushi@2x.png") no-repeat;
      background-size: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  .fs-16 {
    font-size: 0.32rem;
    font-weight: bold;
  }

  .fs-weight {
    font-weight: bold;
  }

  .order-content {
    box-sizing: border-box;
    background: #fbfbfb;
    padding: 0.2rem 0.3rem 1rem;
    /*商品信息*/

    .goods-shop {
      padding: 0.2rem;
      box-sizing: border-box;
      font-size: 0.28rem;
      color: @color-dark-grey;
      position: relative;

      &::before {
        content: "";
        width: 2px;
        height: 0.2rem;
        background: var(--main-color);
        position: absolute;
        top: 50%;
        margin-top: -0.1rem;
        left: 0;
      }
    }

    /*订单信息*/
    .pick-list {
      box-sizing: border-box;
      margin-bottom: 0.3rem;
      margin-top: 0.3rem;

      .order-line {
        background-color: #ffffff;
        padding: 0 0.2rem;
        border-bottom: 1px solid @color-row-line;
        height: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 0.28rem;
        color: @color-dark-grey;

        .account_input {
          width: 5rem;
          border: 1px solid #e0e0e0;
          padding: 0.1rem 0.3rem 0.1rem 0;
          border-radius: 0.25rem;
          outline: none;
        }

        input {
          text-align: right;
          -webkit-appearance: none;

          &::placeholder {
            color: #999;
          }
        }
      }

      .b0 {
        border: none;
      }

      .mix-box {
        // height: 1.1rem;
        line-height: 0.4rem;
        padding-bottom: 0.2rem;
        .sc(0.28rem, @color-dark-grey);

        span {
          // width: 65%;
          .sc(0.28rem, #fe552e);
        }

        p {
          padding-right: 0.38rem;
        }
      }
    }

    .order-info {
      padding: 0 0.2rem;
      box-sizing: border-box;
      background: #fff;
      margin-bottom: 0.3rem;
      margin-top: 0.3rem;

      .info-title {
        font-size: 0.3rem;
        color: @color-dark-grey;
        font-weight: 500;
        padding: 0.2rem 0;
        border-bottom: 1px solid @color-row-line;
        margin-bottom: 0.2rem;
      }

      .info-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 0.28rem;
        color: @color-dark-grey;
        margin-bottom: 0.2rem;
      }

      .info-all {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        font-size: 0.28rem;
        color: @color-dark-grey;
        height: 1rem;
        border-top: 1px solid @color-row-line;

        p {
          margin-left: 5px;
        }
      }

      .order-line {
        border-bottom: 1px solid @color-row-line;
        height: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 0.28rem;
        color: @color-dark-grey;

        .account_input {
          width: 5rem;
          border: 1px solid #e0e0e0;
          padding: 0.1rem 0.3rem 0.1rem 0;
          border-radius: 0.25rem;
          outline: none;
        }

        input {
          text-align: right;
          -webkit-appearance: none;

          &::placeholder {
            color: #999;
          }
        }
      }

      .b0 {
        border: none;
      }

      .mix-box {
        // height: 1.1rem;
        line-height: 0.4rem;
        padding-bottom: 0.2rem;
        .sc(0.28rem, @color-dark-grey);

        span {
          // width: 65%;
          .sc(0.28rem, #fe552e);
        }

        p {
          padding-right: 0.38rem;
        }
      }

      .order-line:nth-last-child(1) {
        border: none;
      }

      .prompt {
        .sc(0.28rem, @color-light-grey);
      }

      .arrow {
        background: url("../../assets/ic_jinru_right_black@3x.png") no-repeat
          100% 50%;
        background-size: 0.2rem 0.2rem;
      }

      .pr36 {
        padding-right: 0.36rem;
      }

      .arrow-grey {
        background: white url(../../assets/Rectangle@3x.png) no-repeat 100% 50%;
        background-size: 0.24rem 0.24rem;
        padding-right: 0.38rem;
      }
      .budget-arrow-grey {
        background: white url(../../assets/Rectangle@3x.png) no-repeat 100% 50%;
        background-size: 0.24rem 0.24rem;
        padding-right: 0.38rem;
      }
      select {
        margin-left: 0;
        font-size: 0.28rem;
        border: none;
        color: #999;
      }
    }

    .max-integral {
      text-align: right;
      padding-bottom: 0.1rem;
    }
  }

  .order-btn {
    .wh(100%, 1rem);
    background: @color-white;
    position: fixed;
    bottom: 0;
    padding: 0 0.3rem;
    box-sizing: border-box;
    box-shadow: 0 0.02rem 0.08rem 0 rgba(0, 0, 0, 0.1);

    span {
      .sc(0.36rem, @color-dark-grey);
      font-weight: bold;
    }

    .pay-btn {
      .wh(2rem, 0.68rem);
      line-height: 0.68rem;
      text-align: center;
      background: var(--main-color);
      border-radius: 0.45rem;
      .sc(0.28rem, @color-white);
    }

    .ban-btn {
      background-color: #999999;
    }
  }

  /*优惠券列表*/

  .coupons-list {
    .yd-popup-show {
      border-radius: 0.2rem 0.2rem 0 0;
      overflow: hidden;
    }

    .title-fix {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 5;
      background: @color-white;
      border-radius: 0.2rem 0.2rem 0 0;

      .title {
        height: 1.14rem;
        line-height: 1.14rem;
        .sc(0.32rem, @color-dark-grey);
        text-align: center;
        position: relative;

        img {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0.24rem;
          margin: auto;
          .wh(0.24rem, 0.24rem);
        }
      }

      /*tab切换*/

      .tab-box {
        height: 0.88rem;
        padding: 0 1.26rem 0 1.44rem;
        .sc(0.28rem, @color-light-grey);

        .tab-item {
          height: 100%;
          line-height: 0.88rem;
          position: relative;
        }

        .select-item {
          .sc(0.28rem, var(--main-color));
          font-weight: 500;
        }

        .line {
          position: absolute;
          .wh(0.5rem, 0.04rem);
          border-radius: 0.06rem;
          background: var(--main-color);
          bottom: 20%;
          left: 0;
          right: 0;
          margin: auto;
        }
      }
    }

    .mb188 {
      margin-bottom: 1.88rem;
    }

    /*优惠券列表*/

    .coupons-box {
      margin-top: 2.02rem;
      overflow: scroll;
      height: 6.58rem;
      -webkit-overflow-scroll: touch; //解决滑动无惯性
      position: absolute;
      left: 0;
      right: 0;
    }

    .empty-couponsBox {
      margin-top: 1.3rem;
    }

    .empty-box {
      margin-top: 2.02rem;
      height: 8.5rem;
      background: #f5f5f5;
    }

    .empty-height {
      margin-top: 0;
      height: 10.5rem;
    }

    /*不使用按钮*/

    .no-coupons {
      width: 100%;
      height: 1.38rem;
      padding: 0 0.24rem;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 3;
      background: @color-white;

      .no-btn {
        height: 0.88rem;
        line-height: 0.88rem;
        text-align: center;
        background: @color-white;
        border-radius: 0.44rem;
        border: 0.02rem solid var(--main-color);
        .sc(0.32rem, var(--main-color));
      }
    }
  }
  // 不可支付弹框样式
  .list-confirm {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .list-confirm-bk {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transform: -webkit-translate(-50%, -50%);
      width: 80%;
      height: 68%;
      background: #fff;
      border-radius: 0.1rem;
      -webkit-border-radius: 0.1rem;
      .list-confirm-btn {
        display: flex;
        height: 0.7rem;
        font-size: 0.3rem;
        color: #fff;
        text-align: center;
        line-height: 0.7rem;
        position: absolute;
        bottom: 0;
        width: 100%;
        .list-confirm-btnleft {
          flex: 1;
          background: #cccccc;
          border-radius: 0 0 0 0.1rem;
          -webkit-border-radius: 0 0 0 0.1rem;
        }
        .list-confirm-btnright {
          flex: 1;
          background: var(--main-color);
          border-radius: 0 0 0.1rem 0;
          -webkit-border-radius: 0 0 0.1rem 0;
        }
      }
      h5 {
        height: 0.8rem;
        text-align: center;
        line-height: 0.8rem;
        border-bottom: 1px solid #dddddd;
      }
      .unavailable-items-box {
        height: 80%;
        overflow-y: auto;
        .unavailable-items {
          padding: 0.16rem 0.23rem;
          display: flex;
          .list-confirm-img {
            width: 1.41rem;
            height: 1.41rem;
            flex: 1;
          }
          .content-box {
            padding: 0.23rem 0 0.23rem 0.23rem;
            flex: 3;
            .content-list-name {
              font-size: 0.28rem;
              max-width: 65%;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
              text-overflow: ellipsis;
              word-break: break-all;
              word-wrap: break-word;
              float: left;
              line-height: 0.36rem;
            }
            .content-list-number {
              float: right;
              font-size: 0.28rem;
              padding-top: 0.23rem;
              text-align: right;
              color: #b3b3b3;
            }
          }
        }
      }
    }
  }
  /*  支付方式选择*/

  .payment-box {
    .yd-popup-show {
      border-radius: 0.2rem 0.2rem 0 0;
      overflow: hidden;
    }
    .pay-item {
      height: 1.18rem;
      line-height: 1.18rem;
      text-align: center;
      border-bottom: 0.02rem solid #ececec;
      .sc(0.32rem, @color-dark-grey);

      &:last-child {
        border: 0;
      }
    }
  }

  .yd-keyboard-title {
    display: none;
  }
}

.fs-weight {
  font-weight: bold;
}

.explode-text {
  text-align: right;
  line-height: 0.4rem;
  padding-bottom: 0.15rem;

  .explode-layout {
    display: flex;
    justify-content: flex-end;
    .text-layout {
      font-size: 0.28rem;
    }
    .pay-type {
      margin-left: 0.25rem;
      .yd-checkbox {
        padding: 0;
        display: flex;
        align-items: center;
        .yd-checkbox-text {
          padding-left: 0.05rem;
        }
      }
    }
  }
}

.color-main {
  color: var(--main-color);
}
</style>
