/**
 * 用于存放一些通用的方法
 */

import { Message } from "element-ui";

/**
 * @todo: 简单的提示
 * @param: {String} message 提示的内容
 * @param: {String} type 提示的类型
 * @return:
 * @author: zcl
 * @date: 2019-08-12 17:11:08
 * @version: V1.0.0
 */
export const tip = (message, type = "info") => {
  Message({
    showClose: true,
    message: message,
    type: type
  });
};

export const console_warn = error =>
  console.warn("Something bad happened: ", error);

/*
 * @Author: zwb
 * @Date: 2019-07-10
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2019-08-12 16:10:58
 */
export function JsonSort(jsonData) {
  try {
    let tempJsonObj = {};
    let sdic = Object.keys(jsonData).sort();
    sdic.map((item, index) => {
      tempJsonObj[item] = jsonData[sdic[index]];
    });
    return tempJsonObj;
  } catch (e) {
    return jsonData;
  }
}

/**
 * @todo: 封装 localStorage 的 set 方法
 * @param: {String} key 键值
 * @param: {any} value 存储的值
 * @return:
 * @author: zcl
 * @date: 2019-08-12 17:31:11
 * @version: V1.0.0
 */
export const setStore = (key, value) => {
  if (!window.localStorage) {
    tip("您的浏览器暂不支持localStorage", "warn");
  }
  if (!key) return;
  if (typeof value !== "string") {
    value = JSON.stringify(value);
  }
  localStorage.setItem(key, value);
};

/**
 * @todo: 封装 localStorage 的 get 方法
 * @param: {String} key 键值
 * @return:
 * @author: zcl
 * @date: 2019-08-12 17:31:11
 * @version: V1.0.0
 */
export const getStore = key => {
  if (!window.localStorage) {
    tip("您的浏览器暂不支持localStorage", "warn");
  }
  if (!key) return;
  return window.localStorage.getItem(key);
};

/**
 * @todo: 封装 localStorage 的 remove 方法
 * @param: {String} key 键值
 * @return:
 * @author: zcl
 * @date: 2019-08-12 17:31:11
 * @version: V1.0.0
 */
export const removeStore = key => {
  if (!window.localStorage) {
    tip("您的浏览器暂不支持localStorage", "warn");
  }
  localStorage.removeItem(key);
};

/**
 * @todo: 封装 localStorage 的 clear 方法
 * @param: {void}
 * @return: void
 * @author: zcl
 * @date: 2019-08-12 17:31:11
 * @version: V1.0.0
 */
export const clearStore = () => {
  if (!window.localStorage) {
    tip("您的浏览器暂不支持localStorage", "warn");
  }
  localStorage.clear();
};

/**
 * @todo: 简易版防抖
 * @param: {Function} func function
 * @param: {Number} delay 间隔时间
 * @return: {Function}
 * @author: zcl
 * @date: 2019-08-13 14:51:49
 * @version: V1.0.0
 */
export function debounce(func, delay) {
  return function() {
    let context = this;
    let args = arguments;
    clearTimeout(func.id);
    func.id = setTimeout(function() {
      func.apply(context, args);
    }, delay);
  };
}

/**
 * @todo: 简易版节流
 * @param: {Function} func function
 * @param: {Number} delay 间隔时间
 * @return: {Function}
 * @author: zcl
 * @date: 2019-08-13 14:57:27
 * @version: V1.0.0
 */
export function throttle(func, delay) {
  let prev;
  return function() {
    let context = this;
    let args = arguments;
    let now = +new Date();
    if (prev && now < prev + delay) {
      clearTimeout(func.id);
      func.id = setTimeout(function() {
        prev = now;
        func.apply(context, args);
      }, delay);
    } else {
      prev = now;
      func.apply(context, args);
    }
  };
}

export function formatData(date) {
  date = new Date(date);
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let hh = date.getHours();
  let mm = date.getMinutes();
  let ss = date.getSeconds();
  return (
    year +
    "-" +
    (month.toString().length > 1 ? month : "0" + month) +
    "-" +
    (day.toString().length > 1 ? day : "0" + day) +
    " " +
    (hh.toString().length > 1 ? hh : "0" + hh) +
    ":" +
    (mm.toString().length > 1 ? mm : "0" + mm) +
    ":" +
    (ss.toString().length > 1 ? ss : "0" + ss)
  );
}

/**
 * @todo: 简易版深拷贝
 * @param: {any} obj 要拷贝的对象，数据源
 * @return: {any} 拷贝的对象
 * @author: zcl
 * @date: 2019-08-22 17:03:51
 * @version: V1.0.0
 */
export const deepClone = obj => JSON.parse(JSON.stringify(obj));

/**
 * @todo: 时间戳转化
 * @param: {TimeStamp} 时间戳
 * @param: {String} 格式
 * @return: {String} 对应格式的日期显示
 * @author: zcl
 * @date: 2019-08-27 18:03:09
 * @version: V1.0.0
*/
export function formatDate(date, fmt) {
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }
  let o = {
    "M+": date.getMonth() + 1,
    "d+": date.getDate(),
    "h+": date.getHours(),
    "m+": date.getMinutes(),
    "s+": date.getSeconds()
  };
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + "";
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? str : padLeftZero(str)
      );
    }
  }
  return fmt;
}

function padLeftZero(str) {
  return ("00" + str).substr(str.length);
}
