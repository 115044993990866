var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "submit-order" },
    [
      _c("HeadNav"),
      _vm.addressStatus
        ? _c(
            "div",
            { staticClass: "order-address", on: { click: _vm.selectAddress } },
            [
              _vm.address_info
                ? _c("div", [
                    _c("p", { staticClass: "user-info" }, [
                      _c("span", { staticClass: "user-name" }, [
                        _vm._v(_vm._s(_vm.address_info.realname)),
                      ]),
                      _c("span", { staticClass: "user-phone" }, [
                        _vm._v(_vm._s(_vm.address_info.mobile)),
                      ]),
                    ]),
                    _c("p", { staticClass: "user-address" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.address_info.area_string) +
                          _vm._s(_vm.address_info.address) +
                          "\n      "
                      ),
                    ]),
                  ])
                : _c("div", { staticClass: "no-address" }, [
                    _vm._v("请填写收货信息"),
                  ]),
              _c("div", { staticClass: "order-line" }),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "order-content" },
        [
          _vm.goods_list.length > 0
            ? _c("order-goods", {
                attrs: {
                  goodsList: _vm.goods_list,
                  show_max: true,
                  showType: true,
                },
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "order-info" },
            [
              _c("div", { staticClass: "info-title" }, [
                _vm._v("订单基本信息"),
              ]),
              _c(
                "div",
                { staticClass: "info-item" },
                [
                  _c("p", [_vm._v("商品总价")]),
                  _vm.businessType == 2
                    ? [
                        _c("p", { staticClass: "fs-weight" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.total_cash > 0 ? `${_vm.total_cash}元` : ``
                              ) +
                              "\n            " +
                              _vm._s(
                                _vm.total_cash > 0 && _vm.total_integral > 0
                                  ? `+`
                                  : ``
                              ) +
                              "\n            " +
                              _vm._s(
                                _vm.total_integral > 0
                                  ? `${_vm.total_integral}积分`
                                  : ``
                              ) +
                              "\n          "
                          ),
                        ]),
                      ]
                    : [
                        _c("p", { staticClass: "fs-weight" }, [
                          _vm._v("¥" + _vm._s(_vm.total_price)),
                        ]),
                      ],
                ],
                2
              ),
              _vm.businessInfo.type !== 3 && _vm.is_kill === 1
                ? _c(
                    "div",
                    { staticClass: "info-item" },
                    [
                      _c("p", [_vm._v("满减")]),
                      _vm.businessType == 2
                        ? [
                            !_vm.full_reduction &&
                            JSON.stringify(_vm.max_marketing_info) === "{}"
                              ? _c(
                                  "p",
                                  {
                                    staticClass: "prompt pr36 arrow",
                                    on: {
                                      click: function ($event) {
                                        _vm.reduction_action = true
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n            暂无可用满减\n          "
                                    ),
                                  ]
                                )
                              : _vm.reductionVal
                              ? _c(
                                  "p",
                                  {
                                    staticClass: "prompt pr36 arrow",
                                    on: {
                                      click: function ($event) {
                                        _vm.reduction_action = true
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n            暂无可用满减\n          "
                                    ),
                                  ]
                                )
                              : JSON.stringify(_vm.max_marketing_info) !== "{}"
                              ? _c(
                                  "p",
                                  {
                                    staticClass:
                                      "pr36 arrow fs-weight color-main",
                                    on: {
                                      click: function ($event) {
                                        _vm.reduction_action = true
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n            -" +
                                        _vm._s(_vm.full_reduction) +
                                        "积分\n          "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        : [
                            !_vm.full_reduction
                              ? _c(
                                  "p",
                                  {
                                    staticClass: "prompt pr36 arrow",
                                    on: {
                                      click: function ($event) {
                                        _vm.reduction_action = true
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n            暂无可用满减\n          "
                                    ),
                                  ]
                                )
                              : _c(
                                  "p",
                                  {
                                    staticClass:
                                      "pr36 arrow fs-weight color-main",
                                    on: {
                                      click: function ($event) {
                                        _vm.reduction_action = true
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n            -¥" +
                                        _vm._s(_vm.full_reduction) +
                                        "\n          "
                                    ),
                                  ]
                                ),
                          ],
                    ],
                    2
                  )
                : _vm._e(),
              _vm.businessInfo.type !== 3 &&
              _vm.add_type !== 3 &&
              _vm.is_group == 1 &&
              _vm.is_kill === 1
                ? _c(
                    "div",
                    { staticClass: "info-item" },
                    [
                      _c("p", [_vm._v("优惠券")]),
                      _vm.businessType == 2
                        ? [
                            _vm.coupon
                              ? _c(
                                  "p",
                                  {
                                    staticClass: "fs-weight color-main",
                                    on: {
                                      click: function ($event) {
                                        _vm.show_coupons = true
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n            -" +
                                        _vm._s(_vm.coupon) +
                                        "积分\n          "
                                    ),
                                  ]
                                )
                              : _c(
                                  "p",
                                  {
                                    staticClass: "prompt pr36 arrow",
                                    on: {
                                      click: function ($event) {
                                        _vm.show_coupons = true
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          _vm.coupons_list.length > 0
                                            ? "请选择优惠券"
                                            : "暂无可用优惠券"
                                        ) +
                                        "\n          "
                                    ),
                                  ]
                                ),
                          ]
                        : [
                            _vm.coupon
                              ? _c(
                                  "p",
                                  {
                                    staticClass: "fs-weight color-main",
                                    on: {
                                      click: function ($event) {
                                        _vm.show_coupons = true
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n            -¥" +
                                        _vm._s(_vm.coupon) +
                                        "\n          "
                                    ),
                                  ]
                                )
                              : _c(
                                  "p",
                                  {
                                    staticClass: "prompt pr36 arrow",
                                    on: {
                                      click: function ($event) {
                                        _vm.show_coupons = true
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          _vm.coupons_list.length > 0
                                            ? "请选择优惠券"
                                            : "暂无可用优惠券"
                                        ) +
                                        "\n          "
                                    ),
                                  ]
                                ),
                          ],
                    ],
                    2
                  )
                : _vm._e(),
              _vm.businessInfo.type !== 3
                ? _c(
                    "div",
                    { staticClass: "info-item" },
                    [
                      _c("p", [_vm._v("商品实付")]),
                      _vm.businessType == 2
                        ? [
                            _c("p", { staticClass: "fs-weight" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.total_cash > 0
                                      ? `${_vm.total_cash}元`
                                      : ``
                                  ) +
                                  "\n            " +
                                  _vm._s(
                                    _vm.total_cash > 0 &&
                                      _vm.actually_integral > 0
                                      ? `+`
                                      : ``
                                  ) +
                                  "\n            " +
                                  _vm._s(
                                    _vm.actually_integral > 0
                                      ? `${_vm.actually_integral}积分`
                                      : ``
                                  ) +
                                  "\n          "
                              ),
                            ]),
                          ]
                        : [
                            _c("p", { staticClass: "fs-weight" }, [
                              _vm._v(
                                "\n            ¥" +
                                  _vm._s(
                                    _vm.actually_price >= 0
                                      ? _vm.actually_price
                                      : 0
                                  ) +
                                  "\n          "
                              ),
                            ]),
                          ],
                    ],
                    2
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "info-item" },
                [
                  _c("p", [_vm._v("运费")]),
                  _vm.businessType == 2
                    ? [
                        _vm.explode_fee_text.length < 1
                          ? _c("p", { staticClass: "fs-weight" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.express_fee_cash == 0 &&
                                      _vm.express_fee == 0
                                      ? 0 + "积分"
                                      : _vm.express_fee_cash > 0
                                      ? _vm.express_fee_cash + "元"
                                      : "" +
                                          _vm.integralPart(
                                            1,
                                            _vm.express_fee,
                                            100,
                                            1
                                          ) >
                                        0
                                      ? _vm.integralPart(
                                          1,
                                          _vm.express_fee,
                                          100,
                                          1
                                        ) + "积分"
                                      : ""
                                  ) +
                                  "\n          "
                              ),
                            ])
                          : _c("p", { staticClass: "fs-weight" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.express_fee_cash > 0
                                      ? _vm.express_fee_cash +
                                          "元" +
                                          (_vm.express_fee > 0
                                            ? "+" + _vm.express_fee + "积分"
                                            : "")
                                      : _vm.express_fee > 0
                                      ? _vm.express_fee + "积分"
                                      : ""
                                  ) +
                                  "\n          "
                              ),
                            ]),
                      ]
                    : [
                        _c("p", { staticClass: "fs-weight" }, [
                          _vm._v("¥" + _vm._s(_vm.express_fee)),
                        ]),
                      ],
                ],
                2
              ),
              _vm._l(_vm.explode_fee_text, function (item, index) {
                return _c("div", { key: index, staticClass: "explode-text" }, [
                  _c("div", { staticClass: "explode-layout" }, [
                    _c("p", { staticClass: "text-layout" }, [
                      _vm._v(_vm._s(item)),
                    ]),
                    _vm.businessType == 2 &&
                    Number(_vm.moneyPay) &&
                    _vm.pay_type != 15
                      ? _c(
                          "div",
                          { staticClass: "pay-type" },
                          [
                            _c(
                              "yd-checkbox-group",
                              {
                                attrs: { size: "14", callback: _vm.selectType },
                                model: {
                                  value: _vm.old_explode_fee_key[index],
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.old_explode_fee_key,
                                      index,
                                      $$v
                                    )
                                  },
                                  expression: "old_explode_fee_key[index]",
                                },
                              },
                              [
                                _c(
                                  "yd-checkbox",
                                  {
                                    attrs: {
                                      val: "old_explode_fee_key[index]",
                                      shape: "circle",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "font-size": "0.28rem" },
                                      },
                                      [_vm._v("现金")]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ])
              }),
              _vm.businessInfo.type !== 3
                ? _c(
                    "div",
                    { staticClass: "info-all" },
                    [
                      _vm.businessType == 2
                        ? [
                            _c("p", { staticClass: "fs-weight color-main" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.total_cash > 0 ||
                                      _vm.express_fee_cash > 0
                                      ? `${(
                                          Number(_vm.total_cash) +
                                          _vm.express_fee_cash
                                        ).toFixed(2)}元`
                                      : ``
                                  ) +
                                  "\n            " +
                                  _vm._s(
                                    (_vm.total_cash > 0 &&
                                      _vm.together_price > 0) ||
                                      (_vm.express_fee_cash &&
                                        _vm.together_price > 0)
                                      ? `+`
                                      : ``
                                  ) +
                                  "\n            " +
                                  _vm._s(
                                    _vm.together_price > 0
                                      ? `${_vm.together_price}积分`
                                      : ``
                                  ) +
                                  "\n          "
                              ),
                            ]),
                          ]
                        : [
                            _c("p", { staticClass: "fs-weight color-main" }, [
                              _vm._v(
                                "\n            ¥" +
                                  _vm._s(
                                    _vm.actually_price >= 0
                                      ? _vm.together_price
                                      : _vm.express_fee.toFixed(2)
                                  ) +
                                  "\n          "
                              ),
                            ]),
                          ],
                      _c("p", [_vm._v("合计")]),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            2
          ),
          _vm.businessInfo.type !== 3
            ? _c("div", { staticClass: "pick-list" }, [
                _vm.recharge_type == 2
                  ? _c("div", { staticClass: "order-line" }, [
                      _c("p", [_vm._v("充值账号")]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.recharge_account,
                            expression: "recharge_account",
                          },
                        ],
                        staticClass: "account_input",
                        staticStyle: { "text-align": "center" },
                        attrs: {
                          type: "text",
                          placeholder: "如手机号、QQ号、油卡号等",
                        },
                        domProps: { value: _vm.recharge_account },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.recharge_account = $event.target.value
                          },
                        },
                      }),
                    ])
                  : _vm._e(),
                _vm.add_type !== 3 &&
                _vm.pay_type !== 7 &&
                _vm.pay_type !== 10 &&
                _vm.pay_type !== 13 &&
                _vm.pay_type !== 15 &&
                _vm.fk_show &&
                _vm.tripartite_type !== "VUE_APP_ZJG"
                  ? _c("div", [
                      _vm.pay_type != 21
                        ? _c(
                            "div",
                            {
                              staticClass: "order-line b0",
                              on: {
                                click: function ($event) {
                                  return _vm.show_payCheck()
                                },
                              },
                            },
                            [
                              _c("p", [_vm._v("支付方式")]),
                              _vm.pay_type === 2 && _vm.businessInfo.type === 1
                                ? _c("p", { staticClass: "arrow-grey" }, [
                                    _vm._v(
                                      "\n            余额支付\n          "
                                    ),
                                  ])
                                : _vm.pay_type === 1 &&
                                  _vm.businessInfo.type !== 3 &&
                                  _vm.moneyPay == 1 &&
                                  _vm.wxStatus
                                ? _c("p", { staticClass: "arrow-grey" }, [
                                    _vm._v(
                                      "\n            微信支付\n          "
                                    ),
                                  ])
                                : _vm.pay_type === 3 &&
                                  _vm.businessInfo.type === 2
                                ? _c("p", { staticClass: "arrow-grey" }, [
                                    _vm._v(
                                      "\n            积分支付\n          "
                                    ),
                                  ])
                                : _vm.pay_type === 5 &&
                                  _vm.businessInfo.type === 1 &&
                                  _vm.bid === "546e0d7595018800a6a44895274b92b1"
                                ? _c("p", { staticClass: "arrow-grey" }, [
                                    _vm._v(
                                      "\n            预付卡支付\n          "
                                    ),
                                  ])
                                : _vm.pay_type === 11
                                ? _c("p", { staticClass: "arrow-grey" }, [
                                    _vm._v("支付宝支付"),
                                  ])
                                : _vm.pay_type === 12
                                ? _c("p", { staticClass: "arrow-grey" }, [
                                    _vm._v("微信支付"),
                                  ])
                                : _c(
                                    "p",
                                    { staticClass: "prompt arrow-grey" },
                                    [_vm._v("选择支付方式")]
                                  ),
                            ]
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _c("div", [
                  _vm.mix_price > 0
                    ? _c("div", { staticClass: "mix-box df fdr jc-sb" }, [
                        _vm.pay_type === 2
                          ? _c("span", [
                              _vm._v(
                                "\n            钱包余额¥" +
                                  _vm._s(_vm.balance) +
                                  "，全部支付后还需\n            "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            支付¥" +
                                  _vm._s(_vm.mix_price) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                        (_vm.pay_type === 3 &&
                          _vm.cashToInt == 1 &&
                          !_vm.cash_intrgral) ||
                        (_vm.pay_type === 10 &&
                          _vm.cashToInt == 1 &&
                          !_vm.cash_intrgral)
                          ? _c(
                              "span",
                              [
                                _vm._v(
                                  "\n            积分余额" +
                                    _vm._s(_vm.integral) +
                                    "\n            " +
                                    _vm._s(
                                      _vm.mix_price > 0
                                        ? `,全部支付后还需${_vm.mix_price}元`
                                        : ``
                                    ) +
                                    "\n            "
                                ),
                                _c(
                                  "yd-checkbox-group",
                                  {
                                    staticStyle: { "margin-top": "0.2rem" },
                                    model: {
                                      value: _vm.morePay,
                                      callback: function ($$v) {
                                        _vm.morePay = $$v
                                      },
                                      expression: "morePay",
                                    },
                                  },
                                  [
                                    _c(
                                      "yd-checkbox",
                                      { attrs: { val: "1", shape: "circle" } },
                                      [_vm._v("同意补价")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        (_vm.pay_type === 3 &&
                          _vm.cashToInt == 1 &&
                          _vm.cash_intrgral) ||
                        (_vm.pay_type === 10 &&
                          _vm.cashToInt == 1 &&
                          _vm.cash_intrgral)
                          ? _c("span", [
                              _vm._v(
                                "\n            您的账号积分余额" +
                                  _vm._s(_vm.integral) +
                                  ",不够支付该订单，无法下单！\n          "
                              ),
                            ])
                          : _vm._e(),
                        (_vm.pay_type === 3 && _vm.cashToInt == 0) ||
                        (_vm.pay_type === 10 && _vm.cashToInt == 0)
                          ? _c("span", [
                              _vm._v(
                                "\n            您的账号积分余额" +
                                  _vm._s(_vm.integral) +
                                  ",不够支付该订单，无法下单！\n          "
                              ),
                            ])
                          : _vm._e(),
                      ])
                    : _c("div", { staticClass: "mix-box df fdr jc-sb" }, [
                        _vm.moneyPay == 0 && _vm.total_cash > 0
                          ? _c("span", [
                              _vm._v(
                                "\n            商城没有开启现金支付，无法下单！\n          "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                ]),
                !_vm.fk_show
                  ? _c("div", [
                      _c(
                        "div",
                        {
                          staticClass: "order-line b0",
                          on: {
                            click: function ($event) {
                              _vm.show_pay = true
                            },
                          },
                        },
                        [
                          _c("p", [_vm._v("支付方式")]),
                          _vm.pay_type == 20
                            ? _c("p", { staticClass: "arrow-grey" }, [
                                _vm._v("饭卡支付"),
                              ])
                            : _vm.pay_type === 1
                            ? _c("p", { staticClass: "arrow-grey" }, [
                                _vm._v("微信支付"),
                              ])
                            : _c("p", { staticClass: "prompt arrow-grey" }, [
                                _vm._v("选择支付方式"),
                              ]),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ])
            : _c("div", { staticClass: "order-info" }, [
                _vm.budget_list
                  ? _c("div", { staticClass: "order-line" }, [
                      _c("p", [_vm._v("预算类型")]),
                      _c("p", { staticClass: "budget-arrow-grey" }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.budget_type_id,
                                expression: "budget_type_id",
                              },
                            ],
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.budget_type_id = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                            },
                          },
                          [
                            _c(
                              "option",
                              {
                                staticStyle: { color: "#999999" },
                                attrs: { value: "" },
                              },
                              [_vm._v("请选择预算类型")]
                            ),
                            _vm._l(_vm.budget_list, function (item, index) {
                              return _c(
                                "option",
                                {
                                  key: index,
                                  staticStyle: { color: "#333" },
                                  domProps: { value: item.id },
                                },
                                [_vm._v(_vm._s(item.name))]
                              )
                            }),
                          ],
                          2
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "order-line", on: { click: _vm.toOrderNote } },
                  [
                    _c("p", [_vm._v("订单备注")]),
                    !_vm.user_remark
                      ? _c("p", { staticClass: "prompt arrow-grey" }, [
                          _vm._v("订单备注哦"),
                        ])
                      : _c("p", { staticClass: "arrow-grey" }, [
                          _vm._v(_vm._s(_vm.user_remark)),
                        ]),
                  ]
                ),
              ]),
          _c("div", { domProps: { innerHTML: _vm._s(_vm.icbcHtml) } }),
        ],
        1
      ),
      _vm.show_coupons
        ? _c(
            "div",
            { staticClass: "coupons-list" },
            [
              _c(
                "yd-popup",
                {
                  attrs: { position: "bottom", height: "79.2%" },
                  model: {
                    value: _vm.show_coupons,
                    callback: function ($$v) {
                      _vm.show_coupons = $$v
                    },
                    expression: "show_coupons",
                  },
                },
                [
                  _c("div", { staticClass: "title-fix" }, [
                    _c("div", { staticClass: "title border-bottom-1px" }, [
                      _vm._v("\n          优惠券\n          "),
                      _c("img", {
                        attrs: {
                          src: require("../../assets/ic_close.png"),
                          alt: "",
                        },
                        on: {
                          click: function ($event) {
                            _vm.show_coupons = false
                          },
                        },
                      }),
                    ]),
                    _c(
                      "div",
                      { staticClass: "tab-box df fdr alc jc-sb" },
                      _vm._l(_vm.tab_list, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            class:
                              index + 1 === _vm.current_tab
                                ? "select-item tab-item"
                                : "tab-item",
                            on: {
                              click: function ($event) {
                                return _vm.selectTab(index)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " + _vm._s(item) + "\n            "
                            ),
                            index + 1 === _vm.current_tab
                              ? _c("div", { staticClass: "line" })
                              : _vm._e(),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                  _vm.coupons_list.length > 0
                    ? _c(
                        "div",
                        { staticClass: "coupons-box" },
                        [
                          _c("CouponList", {
                            ref: "coupons",
                            attrs: {
                              slot: "coupons_list",
                              is_start: true,
                              list: _vm.coupons_list,
                            },
                            on: { operate: _vm.operate },
                            slot: "coupons_list",
                          }),
                          _c("div", {
                            staticStyle: { "margin-bottom": "1.38rem" },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.coupons_list.length === 0
                    ? _c(
                        "div",
                        { staticClass: "empty-box" },
                        [
                          _c("Empty", {
                            attrs: { tip: "暂无优惠券～", icon: _vm.emp_img },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.coupons_list.length > 0
                    ? _c("div", { staticClass: "no-coupons" }, [
                        _c(
                          "div",
                          {
                            staticClass: "no-btn",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.noUseCoupons.apply(null, arguments)
                              },
                            },
                          },
                          [_vm._v("不使用优惠券")]
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.reduction_action
        ? _c(
            "div",
            { staticClass: "coupons-list" },
            [
              _c(
                "yd-popup",
                {
                  attrs: { position: "bottom", height: "79.2%" },
                  model: {
                    value: _vm.reduction_action,
                    callback: function ($$v) {
                      _vm.reduction_action = $$v
                    },
                    expression: "reduction_action",
                  },
                },
                [
                  _c("div", { staticClass: "title-fix" }, [
                    _c("div", { staticClass: "title border-bottom-1px" }, [
                      _vm._v("\n          满减活动\n          "),
                      _c("img", {
                        attrs: {
                          src: require("../../assets/ic_close.png"),
                          alt: "",
                        },
                        on: {
                          click: function ($event) {
                            _vm.reduction_action = false
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm.reduction_list.length > 0
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "coupons-box couponsBox empty-couponsBox",
                        },
                        [
                          _c("CouponList", {
                            ref: "reduction",
                            attrs: {
                              slot: "reduction_list",
                              is_start: true,
                              list: _vm.reduction_list,
                            },
                            on: { operate: _vm.useReduction },
                            slot: "reduction_list",
                          }),
                          _c("div", {
                            staticStyle: { "margin-bottom": "1.38rem" },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.reduction_list.length === 0
                    ? _c(
                        "div",
                        { staticClass: "empty-box couponsBox empty-height" },
                        [
                          _c("Empty", {
                            attrs: { tip: "暂无满减～", icon: _vm.emp_img },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.reduction_list.length > 0
                    ? _c("div", { staticClass: "no-coupons couponsBox" }, [
                        _c(
                          "div",
                          {
                            staticClass: "no-btn",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.noUseReduction.apply(null, arguments)
                              },
                            },
                          },
                          [_vm._v("不使用满减")]
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.show_card_pay
        ? _c(
            "div",
            [_c("card_pay", { on: { cardPayOrder: _vm.cardPayOrder } })],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "payment-box" },
        [
          _vm.jpkStatus && _vm.fk_show
            ? _c(
                "yd-popup",
                {
                  attrs: { position: "bottom" },
                  model: {
                    value: _vm.show_pay,
                    callback: function ($$v) {
                      _vm.show_pay = $$v
                    },
                    expression: "show_pay",
                  },
                },
                [
                  !_vm.wxStatus
                    ? _c(
                        "div",
                        {
                          staticClass: "pay-item",
                          on: {
                            click: function ($event) {
                              return _vm.changePayType(11)
                            },
                          },
                        },
                        [_vm._v("\n        支付宝支付\n      ")]
                      )
                    : _vm._e(),
                  _vm.wxStatus
                    ? _c(
                        "div",
                        {
                          staticClass: "pay-item",
                          on: {
                            click: function ($event) {
                              return _vm.changePayType(1)
                            },
                          },
                        },
                        [_vm._v("\n        微信支付\n      ")]
                      )
                    : _c(
                        "div",
                        {
                          staticClass: "pay-item",
                          on: {
                            click: function ($event) {
                              return _vm.changePayType(12)
                            },
                          },
                        },
                        [_vm._v("微信支付")]
                      ),
                  _c(
                    "div",
                    {
                      staticClass: "pay-item",
                      staticStyle: { color: "#999" },
                      on: {
                        click: function ($event) {
                          _vm.show_pay = false
                        },
                      },
                    },
                    [_vm._v("\n        取消\n      ")]
                  ),
                ]
              )
            : _vm.fk_show
            ? _c(
                "yd-popup",
                {
                  attrs: { position: "bottom" },
                  model: {
                    value: _vm.show_pay,
                    callback: function ($$v) {
                      _vm.show_pay = $$v
                    },
                    expression: "show_pay",
                  },
                },
                [
                  _vm.businessInfo.type === 1
                    ? _c(
                        "div",
                        {
                          staticClass: "pay-item",
                          on: {
                            click: function ($event) {
                              return _vm.changePayType(2)
                            },
                          },
                        },
                        [_vm._v("\n        余额支付\n      ")]
                      )
                    : _vm._e(),
                  _vm.businessInfo.type !== 3 &&
                  _vm.moneyPay == 1 &&
                  _vm.wxStatus &&
                  _vm.typeStatus
                    ? _c(
                        "div",
                        {
                          staticClass: "pay-item",
                          on: {
                            click: function ($event) {
                              return _vm.changePayType(1)
                            },
                          },
                        },
                        [_vm._v("\n        微信支付\n      ")]
                      )
                    : _vm._e(),
                  _vm.businessInfo.type === 2
                    ? _c(
                        "div",
                        {
                          staticClass: "pay-item",
                          on: {
                            click: function ($event) {
                              return _vm.changePayType(3)
                            },
                          },
                        },
                        [_vm._v("\n        积分支付\n      ")]
                      )
                    : _vm._e(),
                  _vm.businessInfo.type === 1 &&
                  _vm.bid === "546e0d7595018800a6a44895274b92b1"
                    ? _c(
                        "div",
                        {
                          staticClass: "pay-item",
                          on: {
                            click: function ($event) {
                              return _vm.changePayType(5)
                            },
                          },
                        },
                        [_vm._v("\n        预付卡支付\n      ")]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass: "pay-item",
                      staticStyle: { color: "#999" },
                      on: {
                        click: function ($event) {
                          _vm.show_pay = false
                        },
                      },
                    },
                    [_vm._v("\n        取消\n      ")]
                  ),
                ]
              )
            : !_vm.fk_show
            ? _c(
                "yd-popup",
                {
                  attrs: { position: "bottom" },
                  model: {
                    value: _vm.show_pay,
                    callback: function ($$v) {
                      _vm.show_pay = $$v
                    },
                    expression: "show_pay",
                  },
                },
                [
                  _vm.businessInfo.type === 1
                    ? _c(
                        "div",
                        {
                          staticClass: "pay-item",
                          on: {
                            click: function ($event) {
                              return _vm.changePayType(20)
                            },
                          },
                        },
                        [_vm._v("\n        饭卡支付\n      ")]
                      )
                    : _vm._e(),
                  _vm.businessInfo.type !== 3 &&
                  _vm.moneyPay == 1 &&
                  _vm.wxStatus
                    ? _c(
                        "div",
                        {
                          staticClass: "pay-item",
                          on: {
                            click: function ($event) {
                              return _vm.changePayType(1)
                            },
                          },
                        },
                        [_vm._v("\n        微信支付\n      ")]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass: "pay-item",
                      staticStyle: { color: "#999" },
                      on: {
                        click: function ($event) {
                          _vm.show_pay = false
                        },
                      },
                    },
                    [_vm._v("\n        取消\n      ")]
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "yd-popup",
        {
          attrs: { position: "bottom" },
          model: {
            value: _vm.is_pay,
            callback: function ($$v) {
              _vm.is_pay = $$v
            },
            expression: "is_pay",
          },
        },
        [
          _c(
            "div",
            { staticClass: "fk-info-item" },
            [
              _c("yd-input", {
                attrs: {
                  slot: "right",
                  required: "",
                  "show-success-icon": false,
                  "show-error-icon": false,
                  placeholder: "请输入饭卡卡号",
                },
                slot: "right",
                model: {
                  value: _vm.mobile,
                  callback: function ($$v) {
                    _vm.mobile = $$v
                  },
                  expression: "mobile",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "fk-info-item" },
            [
              _c("yd-input", {
                attrs: {
                  slot: "right",
                  type: "password",
                  required: "",
                  "show-success-icon": false,
                  "show-error-icon": false,
                  placeholder: "请输入饭卡密码",
                },
                slot: "right",
                model: {
                  value: _vm.password,
                  callback: function ($$v) {
                    _vm.password = $$v
                  },
                  expression: "password",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { margin: ".2rem 0" } },
            [
              _vm.confirmStaus
                ? _c(
                    "yd-button",
                    {
                      staticClass: "pay-sty",
                      attrs: { bgcolor: "#409eff" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.goPay()
                        },
                      },
                    },
                    [_vm._v("确认")]
                  )
                : _c(
                    "yd-button",
                    { staticClass: "pay-sty", attrs: { bgcolor: "#409eff" } },
                    [_vm._v("确认")]
                  ),
            ],
            1
          ),
        ]
      ),
      _c("ConfirmAlert", {
        attrs: {
          value: _vm.no_pwd,
          is_delete: false,
          confirmText: _vm.confirmTxt,
          confirmBtnText: _vm.confirmBtnText,
        },
        on: {
          cancel: function ($event) {
            _vm.no_pwd = false
            _vm.is_request = false
          },
          confirm: _vm.confirmPwd,
        },
      }),
      _c("ConfirmAlert", {
        attrs: {
          value: _vm.kunshanConfirm,
          is_delete: false,
          confirmText: _vm.kunshangTxt,
          cancelBtnText: "取消支付",
          confirmBtnText: "确定支付",
        },
        on: { cancel: _vm.kunshanCancel, confirm: _vm.createOrder },
      }),
      _c(
        "div",
        {
          staticClass: "list-confirm",
          style: { display: this.has_unavailable_sty },
        },
        [
          _c("div", { staticClass: "list-confirm-bk" }, [
            _vm.has_title === 1
              ? _c("h5", [_vm._v("以下商品暂停售卖")])
              : _c("h5", [_vm._v("以下商品暂时库存不足")]),
            _c(
              "div",
              { staticClass: "unavailable-items-box" },
              _vm._l(_vm.unavailable_items, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "unavailable-items" },
                  [
                    _c("img", {
                      staticClass: "list-confirm-img",
                      attrs: { src: item.small_pic },
                    }),
                    _c("div", { staticClass: "content-box" }, [
                      _c("p", { staticClass: "content-list-name" }, [
                        _vm._v(_vm._s(item.item_name)),
                      ]),
                      _c("div", { staticClass: "content-list-number" }, [
                        _vm._v("x" + _vm._s(item.number)),
                      ]),
                    ]),
                  ]
                )
              }),
              0
            ),
            _c("div", { staticClass: "list-confirm-btn" }, [
              _c(
                "div",
                {
                  staticClass: "list-confirm-btnleft",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.checkBtnOK.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("\n          知道了\n        ")]
              ),
              _c(
                "div",
                {
                  staticClass: "list-confirm-btnright",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.checkBtnRemove.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("\n          移除商品\n        ")]
              ),
            ]),
          ]),
        ]
      ),
      _c("yd-keyboard", {
        ref: "keyboard",
        attrs: { callback: _vm.checkPwd, disorder: "", title: "" },
        model: {
          value: _vm.show_keyboard,
          callback: function ($$v) {
            _vm.show_keyboard = $$v
          },
          expression: "show_keyboard",
        },
      }),
      _c(
        "div",
        { staticClass: "order-btn df alc jc-sb" },
        [
          _vm.businessType == 2
            ? [
                _c("span", [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.total_cash > 0 || _vm.express_fee_cash > 0
                          ? `${(
                              Number(_vm.total_cash) + _vm.express_fee_cash
                            ).toFixed(2)}元`
                          : ``
                      ) +
                      "\n        " +
                      _vm._s(
                        (_vm.total_cash > 0 && _vm.together_price > 0) ||
                          (_vm.express_fee_cash && _vm.together_price > 0)
                          ? `+`
                          : ``
                      ) +
                      "\n        " +
                      _vm._s(
                        _vm.together_price > 0
                          ? `${_vm.together_price}积分`
                          : ``
                      ) +
                      "\n      "
                  ),
                ]),
              ]
            : [
                _c("span", [
                  _vm._v(
                    "\n        ¥" +
                      _vm._s(
                        _vm.actually_price >= 0
                          ? _vm.together_price
                          : _vm.express_fee.toFixed(2)
                      ) +
                      "\n      "
                  ),
                ]),
              ],
          !_vm.is_request
            ? _c(
                "div",
                {
                  staticClass: "pay-btn",
                  class: _vm.btnClass,
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.checkCar.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("\n      " + _vm._s(_vm.btn_text) + "\n    ")]
              )
            : _c("div", { staticClass: "pay-btn", class: _vm.btnClass }, [
                _vm._v(_vm._s(_vm.btn_text)),
              ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }